import React, { Component } from 'react';
// import PropTypes from 'prop-types';
import styles from './studymatarialdetails.module.scss';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ShimmerThumbnail } from "react-shimmer-effects-18";
// import Moment from 'react-moment'
import { Tab, Tabs } from 'react-bootstrap'
import UserService from '../../Services/user-services';
import withRouter from '../../Common/with-router';
class Studymatarialdetails extends Component {
  constructor(props) {
    super(props);
    this.state = {
      shimmer: true,
      instituteName: "",
      name: "",
      tags: "",
      subject: "",
      categories: "",

      tabKey: "one",
      imageUpload: [],
      shimmerFolder: true,
      isImageDisable:false,
      isDocumentDisable:false,
      isVideoDisable:false,
      isYouTubeLinkDisable:false
    }
  }
  componentDidMount() {
    let params = {
      id: this.props.params.id,

    }
    console.log("ID==>", params)
    UserService.StudyMatrialDetail(params).then(
      response => {
        console.log("REsponse==>", response)
        if (response.data.status === 200) {
          this.setState({
            name: response.data.data.studyMaterial.name,
            instituteName: response.data.data.studyMaterial.InstituteName,
            tags: response.data.data.studyMaterial.Tags,
            subject: response.data.data.studyMaterial.SubjectName,
            categories: response.data.data.studyMaterial.CategoryName,
            imageUpload: response.data.data.resourcesFiles

          },
            this.setState({
              shimmerFolder: true
            })
          )
        }
      })

  }
  document = (e) => {
    window.open(e, "_blank")
    // window.location.replace(e,"_blank");

  }
  render() {
    return (
      <div className='main'>
        <div className='d-flex justify-content-between'>
          <div className='detailsHeading'>
            <span className='leftIconHeading' onClick={() => this.props.navigate(-1)}><AiOutlineArrowLeft /></span>
            Study Matarial Details
          </div>
        </div>
        <div>
          {
            this.state.shimmer === true ?
              <div className='details'>
                <div className='row justify-content-start'>

                  <div className='col-md-4 m-0 pt-3 col-sm-12'>
                    <label className='detailTitle'>
                      Institute Name
                    </label>
                    <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.instituteName} ></input>
                  </div>


                  <div className='col-md-4 m-0 pt-3 col-sm-12'>
                    <label className='detailTitle'>
                      Name
                    </label>
                    <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.name} ></input>
                  </div>
                  <div className='col-md-4 m-0 pt-3 col-sm-12'>
                    <label className='detailTitle'>
                      Tags
                    </label>
                    <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.tags} ></input>
                  </div>
                  <div className='col-md-4 m-0 pt-3 col-sm-12'>
                    <label className='detailTitle'>
                      Subject
                    </label>
                    <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.subject} ></input>
                  </div>

                  <div className='col-md-4 m-0 pt-3 col-sm-12'>
                    <label className='detailTitle'>
                      categories
                    </label>
                    <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.categories} ></input>
                  </div>


                </div>
              </div>
              : <ShimmerThumbnail />
          }
        </div>
        <div className='pt-5 d-flex justify-content-center h3 fw-bold'>
          Resources Files
        
        </div>
        <div className='pt-4'>
          <Tabs className='w-100 d-flex justify-content-center border-0' activeKey={this.state.tabKey} onSelect={(e) => this.setState({
            tabKey: e
          })}>

            <Tab eventKey="one" title="Images" >
              {
                this.state.imageUpload.map((data, index) => {
                  return (
                    <div key={index} >
                      {
                        data.fileType === 2 ?
                          <div className='col-lg-3 col-sm-12' style={{ margin: '10px' }} key={index}>
                            <img src={data.fileLink
                            } alt='' className={styles.imgFluid}
                              onClick={() => {
                                this.document(data.fileLink)
                              }}
                            ></img>
                          </div>
                          :
                          <div className='d-flex justify-content-center fw-bold pt-5 h3 '>
                            No Data Found
                          </div>


                      }
                    </div>
                  )

                })
              }
            </Tab>
            <Tab eventKey="two" title="Document" >
              {
                this.state.imageUpload.map((data, index) => {
                  return (
                    <div key={index} >
                      {
                        data.fileType === 3 ?
                          <div className='col-lg-3 col-sm-12' style={{ margin: '10px' }} key={index}>
                            <img src={data.fileLink
                            } alt='' className={styles.imgFluid}
                              onClick={() => {
                                this.document(data.fileLink)
                              }}
                            ></img>
                          </div>
                          :
                          <div className='d-flex justify-content-center fw-bold pt-5 h3 '>
                            No Data Found
                          </div>


                      }
                    </div>
                  )

                })
              }
            </Tab>
            <Tab eventKey="three" title="Video" >
              {
                this.state.imageUpload.map((data, index) => {
                  return (
                    <div key={index}>
                      {
                        data.fileType === 4 ?
                          <div className='col-lg-3 col-sm-12' style={{ margin: '10px' }} key={index}>

                            <video controls className={styles.imgFluid} >
                              <source src={data.fileLink} type="video/mp4" />
                            </video>
                          </div>
                          :
                          <div className='d-flex justify-content-center fw-bold pt-5 h3 '>
                            No Data Found
                          </div>
                      }
                    </div>
                  )

                })
              }
            </Tab>
            <Tab eventKey="four" title="YouTube Link" >
              {
                this.state.imageUpload.map((data, index) => {
                  return (
                    <div  key={index}>
                      {
                        data.fileType === 5 ?
                          <div className='col-lg-3 col-sm-12' style={{ margin: '10px' }} key={index}>

                            <iframe title={index} src={"https://www.youtube.com/embed/" + data.fileLink} key={index} className='ratio ratio-1x1'></iframe>
                          </div>
                          :
                          <div className='d-flex justify-content-center fw-bold pt-5 h3 '>
                            No Data Found
                          </div>
                      }
                    </div>
                  )
                })
              }
            </Tab>
          </Tabs>
        </div>
      </div>
    )
  }
}

Studymatarialdetails.propTypes = {};

Studymatarialdetails.defaultProps = {};

export default withRouter(Studymatarialdetails);
