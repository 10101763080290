import React,{Component} from 'react';
// import PropTypes from 'prop-types';
import styles from "./Dashboard.module.scss"
import UserService from "../../Services/user-services";
import Chart from 'react-apexcharts'
// import Loader from '../Loader/Loader'
import Moment from 'react-moment';
import authHeader from '../../Services/auth-header';
import { ShimmerThumbnail, ShimmerBadge } from "react-shimmer-effects-18";
import { IoIosArrowDown } from "react-icons/io";
class Dashboard extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isLoader: false,
      content: "",
      isDataLoaded: false,
      isGraphTeacher: false,
      isGraphStudent: false,
      isGraphClasses: false,
      isGraphInstitute: false,
      isDashBoardTable: false,
      isDASHBOARDINSTITUTE: false,
      dashboardData: [
        {
          Classes: 0,
          Institute: 0,
          Students: 0,
          Teachers: 0
        }
      ],
      Classes: [
        {
          options: {
            chart: {
              margin: 0,
              height: '100px',
              minHeight: '100px',
              // width: '5px',
              type: 'area',

              toolbar: {
                show: false
              },
              zoom: {
                enabled: false,
                type: 'x',
                autoScaleYaxis: false,
                zoomedArea: {
                  fill: {
                    color: '#90CAF9',
                    opacity: 0.4
                  },
                  stroke: {
                    color: '#0D47A1',
                    opacity: 0.4,
                    width: 1
                  }
                }
              }
            },
            dataLabels: {
              enabled: false
            },
            stroke: {
              curve: 'smooth'
            },
            tooltip: {
              shared: false,
              intersect: false
            },
            toolbar: {
              show: false
            },
            xaxis: {
              type: 'category',
              categories: [

              ],
              labels: {
                rotate: 0
              },
              tickPlacement: 'on'

            },
            yaxis: {
              show: false
            },
            grid: {
              show: false,
            },
          },
          series: [
            {
              name: 'Instittute',
              data: [
                0, 0, 0, 0, 0, 0
              ]
            }],
          CDATE: 0,
          CCOUNT: 0,
          SDATE: 0,
          SCOUNT: 0,
          TDATE: 0,
          TCOUNT: 0,
          IDATE: 0,
          ICOUNT: 0,

        }
      ],
      graphData: [],
      isDASHBOARDINSTITUTETABLE: [],
      filterValue: "0",
      dashboardState: false

    };


  }
  count = 0;

  async componentDidMount() {
    // console.log = () => {}
    const auth = authHeader()
    console.log("Auth Header==>", auth)
    if (auth == null) {
      return window.location.href = '/Login';
    } 
    else {
      let params = {
        type: parseInt(this.state.filterValue)
      }
      // UserService.adminDashboard(params).then(
      //   response => {
      //     console.log('Response==>', response);
      //     if (response?.data?.status === 200) {
      //       this.setState({
      //         dashboardData: [response.data.data.data],
      //         isDASHBOARDINSTITUTETABLE: response.data.data.instituteUser,
      //         dashboardState: true
      //       },
      //         () => {

      //           this.setState({
      //             isDashBoardTable: true,
      //             isLoader: true
      //           })
      //         }
      //       )


      //     }                         
      //   },
      //   error => {
      //     console.log(error)
      //   }
      // );
      // UserService.classGraph(params).then(
      //   response => {
      //     if (response?.data?.status === 200) {
      //       console.log("hello there", this.state.Classes[0].series[0].data);
      //       this.setState({
      //         options: {
      //           ...this.state.Classes[0].options,
      //         },
      //         xaxis: {
      //           ...this.state.Classes[0].categories,
      //         },
      //         isGraphClasses: true
      //       })
      //       let responseLabels = response.data.data.labels;
      //       let classesValues = this.state.Classes
      //       classesValues[0].options.xaxis.categories = [...responseLabels]
      //       console.log("Spredad==>", classesValues)
      //       let responseSeries = response.data?.data.series;
      //       let seriesValues = this.state.Classes
      //       seriesValues[0].series[0].data = [...responseSeries]
      //       console.log("Spredad==>", seriesValues)
      //       let responseEndDate = response.data?.data.endDate
      //       let endDateValues = this.state.Classes
      //       endDateValues[0].CDATE = [...responseEndDate]
      //       console.log("Spredad==>", endDateValues)
      //       let CountValues = this.state.Classes
      //       CountValues[0].CCOUNT = [response.data?.data.total]
      //       console.log("Spredad==>", CountValues)

      //     }
      //   },
      //   error => {
      //     console.log(error)
      //   }
      // );
      // UserService.teacherGraph(params).then(
      //   response => {

      //     if (response.data?.status === 200) {
      //       console.log("hello there", this.state.Classes[0].series[0].data);
      //       this.setState({
      //         options: {
      //           ...this.state.Classes[0].options,
      //         },
      //         xaxis: {
      //           ...this.state.Classes[0].categories,
      //         },
      //         isGraphTeacher: true
      //       })
      //       let responseLabels = response.data?.data.labels;

      //       let classesValues = this.state.Classes
      //       classesValues[0].options.xaxis.categories = [...responseLabels]

      //       console.log("Spredad==>", classesValues)


      //       let responseSeries = response.data.data.series;

      //       let seriesValues = this.state.Classes
      //       seriesValues[0].series[0].data = [...responseSeries]

      //       console.log("Spredad==>", seriesValues)


      //       let responseEndDate = response.data?.data.endDate

      //       let endDateValues = this.state.Classes
      //       endDateValues[0].TDATE = [...responseEndDate]

      //       console.log("Spredad==>", endDateValues)
      //       let CountValues = this.state.Classes
      //       CountValues[0].TCOUNT = [response.data?.data.total]

      //       console.log("Spredad==>", CountValues)


      //     }
      //   },
      //   error => {
      //     console.log(error)
      //   }
      // );
      // UserService.studentGraph(params).then(
      //   response => {
      //     if (response?.data?.status === 200) {
      //       console.log("hello there", this.state.Classes[0].series[0].data);
      //     this.setState({ isGraphStudent: true })
      //       this.setState({
      //         options: {
      //           ...this.state.Classes[0].options,
      //         }
      //       })
      //       this.setState({
      //         xaxis: {
      //           ...this.state.Classes[0].categories,

      //         }
      //       })
      //       let responseLabels = response.data.data.labels;
      //       let classesValues = this.state.Classes
      //       classesValues[0].options.xaxis.categories = [...responseLabels]
      //       console.log("Spredad==>", classesValues)
      //       let responseSeries = response.data.data.series;
      //       let seriesValues = this.state.Classes
      //       seriesValues[0].series[0].data = [...responseSeries]
      //       console.log("Spredad==>", seriesValues)
      //       let responseEndDate = response.data.data.endDate
      //       let endDateValues = this.state.Classes
      //       endDateValues[0].SDATE = [...responseEndDate]
      //       console.log("Spredad==>", endDateValues)
      //       let CountValues = this.state.Classes
      //       CountValues[0].SCOUNT = [response.data.data.total]
      //       console.log("Spredad==>", CountValues)
      //     } 
      //   },
      //   error => {
      //     console.log(error)
      //   }
      // );
      // UserService.instituteGraph(params).then(
      //   response => {
        
      //     if (response?.data?.status === 200) {

      //       // this.state.Classes[0].series[0].data = response.data.data.series;
      //       // this.state.Classes[0].options.xaxis.categories = response.data.data.labels;
      //       // this.state.Classes[0].IDATE = response.data.data.endDate
      //       // this.state.Classes[0].ICOUNT = response.data.data.total

      //       this.setState({
      //         xaxis: {
      //           ...this.state.Classes[0].categories,

      //         },

      //         isGraphInstitute: true,
      //         options: {
      //           ...this.state.Classes[0].options,
      //         },


      //         //  ...this.state.Classes[0].options.xaxis.categories = response.data.data.labels

      //       })

      //       // let Classes = [...this.state.Classes[0].options.xaxis]
      //       let responseLabels = response.data.data.labels;

      //       let classesValues = this.state.Classes
      //       classesValues[0].options.xaxis.categories = [...responseLabels]

      //       console.log("Spredad==>", classesValues)


      //       let responseSeries = response.data.data.series;

      //       let seriesValues = this.state.Classes
      //       seriesValues[0].series[0].data = [...responseSeries]

      //       console.log("Spredad==>", seriesValues)


      //       let responseEndDate = response.data.data.endDate

      //       let endDateValues = this.state.Classes
      //       endDateValues[0].IDATE = [...responseEndDate]

      //       console.log("Spredad==>", endDateValues)



      //       // count




      //       let CountValues = this.state.Classes
      //       CountValues[0].ICOUNT = [response.data.data.total]

      //       console.log("Spredad==>", CountValues)



      //     }
      //   },
      //   error => {
      //   console.log(error)
      //   }
      // );
   
    }
  }
  filterUpdate = (e) => {
    console.log("Filter==>", e.target.value)
    this.setState({
      filterValue: e.target.value,
      dashboardState: false,
      isGraphTeacher: false,
      isGraphStudent: false,
      isGraphClasses: false,
      isGraphInstitute: false,
    },
      () => {
        let params = {
          type: parseInt(this.state.filterValue)
        }
        UserService.adminDashboard(params).then(
          response => {
            console.log(response);
            if (response?.data?.status === 200) {
              this.setState({
                dashboardData: [response.data.data.data],
                isDASHBOARDINSTITUTETABLE: response.data.data.instituteUser,
                dashboardState: true
              },
                () => {

                  this.setState({
                    isDashBoardTable: true,
                    isLoader: true
                  })
                }
              )


            } 
          },
          error => {
           console.log(error)
          }
        );
        UserService.classGraph(params).then(
          response => {
          
            if (response?.data?.status === 200) {
              console.log("hello there", this.state.Classes[0].series[0].data);

              this.setState({
                options: {
                  ...this.state.Classes[0].options,
                },
                xaxis: {
                  ...this.state.Classes[0].categories,

                },
                isGraphClasses: true

              })
              let responseLabels = response.data.data.labels;

              let classesValues = this.state.Classes
              classesValues[0].options.xaxis.categories = [...responseLabels]

              console.log("Spredad==>", classesValues)


              let responseSeries = response.data.data.series;

              let seriesValues = this.state.Classes
              seriesValues[0].series[0].data = [...responseSeries]

              console.log("Spredad==>", seriesValues)


              let responseEndDate = response.data.data.endDate

              let endDateValues = this.state.Classes
              endDateValues[0].CDATE = [...responseEndDate]

              console.log("Spredad==>", endDateValues)
              let CountValues = this.state.Classes
              CountValues[0].CCOUNT = [response.data.data.total]

              console.log("Spredad==>", CountValues)

            }
          },
          error => {
            console.log(error)
          }
        );
        UserService.teacherGraph(params).then(
          response => {
           
            if (response?.data?.status === 200) {
              console.log("hello there", this.state.Classes[0].series[0].data);
              this.setState({
                options: {
                  ...this.state.Classes[0].options,
                },
                xaxis: {
                  ...this.state.Classes[0].categories,

                },
                isGraphTeacher: true
              })
              let responseLabels = response.data.data.labels;

              let classesValues = this.state.Classes
              classesValues[0].options.xaxis.categories = [...responseLabels]

              console.log("Spredad==>", classesValues)


              let responseSeries = response.data.data.series;

              let seriesValues = this.state.Classes
              seriesValues[0].series[0].data = [...responseSeries]

              console.log("Spredad==>", seriesValues)


              let responseEndDate = response.data.data.endDate

              let endDateValues = this.state.Classes
              endDateValues[0].TDATE = [...responseEndDate]

              console.log("Spredad==>", endDateValues)
              let CountValues = this.state.Classes
              CountValues[0].TCOUNT = [response.data.data.total]

              console.log("Spredad==>", CountValues)


            }else {

            }
          },
          error => {
            console.log(error)
          }
        );
        UserService.studentGraph(params).then(
          response => {
            console.log('GRaph Labels', response.data.data);
            if (response?.data?.status === 200) {
              console.log("hello there", this.state.Classes[0].series[0].data);
              this.setState({ isGraphStudent: true })
              this.setState({
                options: {
                  ...this.state.Classes[0].options,
                }
              })
              this.setState({
                xaxis: {
                  ...this.state.Classes[0].categories,
                }
              })
              let responseLabels = response.data.data.labels;
              let classesValues = this.state.Classes
              classesValues[0].options.xaxis.categories = [...responseLabels]
              console.log("Spredad==>", classesValues)
              let responseSeries = response.data.data.series;
              let seriesValues = this.state.Classes
              seriesValues[0].series[0].data = [...responseSeries]
              console.log("Spredad==>", seriesValues)
              let responseEndDate = response.data.data.endDate
              let endDateValues = this.state.Classes
              endDateValues[0].SDATE = [...responseEndDate]
              console.log("Spredad==>", endDateValues)
              let CountValues = this.state.Classes
              CountValues[0].SCOUNT = [response.data.data.total]
              console.log("Spredad==>", CountValues)
            }
          },
          error => {
           console.log(error)
          }
        );
        UserService.instituteGraph(params).then(
          response => {  
            if (response?.data?.status === 200) {
              this.setState({
                xaxis: {
                  ...this.state.Classes[0].categories,
                },
                isGraphInstitute: true,
                options: {
                  ...this.state.Classes[0].options,
                },
                //  ...this.state.Classes[0].options.xaxis.categories = response.data.data.labels
              })
              // let Classes = [...this.state.Classes[0].options.xaxis]
              let responseLabels = response.data.data.labels;
              let classesValues = this.state.Classes
              classesValues[0].options.xaxis.categories = [...responseLabels]
              console.log("Spredad==>", classesValues)
              let responseSeries = response.data.data.series;
              let seriesValues = this.state.Classes
              seriesValues[0].series[0].data = [...responseSeries]
              console.log("Spredad==>", seriesValues)
              let responseEndDate = response.data.data.endDate
              let endDateValues = this.state.Classes
              endDateValues[0].IDATE = [...responseEndDate]
              console.log("Spredad==>", endDateValues)
              // count         
              let CountValues = this.state.Classes
              CountValues[0].ICOUNT = [response.data.data.total]
              console.log("Spredad==>", CountValues)
            } 
          },
          error => {
            console.log(error)
          }
        );
      }
    )
  }
  render() {
    return (
      <div className='main border'>
          <div>
            <div className='d-flex justify-content-between  flex-wrap'>
              <div>
                <h3 className={styles.h2Font}>
                  Welcome
                </h3>
              </div>
              <div>
                <div className={styles.columnFilter}>
                  <div className={styles.ArrowIcon}>
                    <IoIosArrowDown className={styles.ArrowIconchange} />
                    <select className='form-control ' value={this.state.filterValue} onChange={this.filterUpdate}>
                      <option value='0'>All</option>
                      <option value='1'>Last Week</option>
                      <option value="2">Last Month</option>
                      <option value="3">Last 6 Month</option>
                      <option value="4">Last Year</option>
                    </select>
                  </div>
                </div>
              </div>
            </div>
            <div className='col pt-2'>
              <div className='row'>
                {
                  Object.entries(this.state.dashboardData[0]).map((key, i) => {
                    return (
                      <div className='col-lg-3 col-sm-12 p-3 ' key={i}>
                        <div className={"border"}>
                          <div className={key[0]}>
                            <div className={styles.paddingBox}>
                              <div className={styles.textName}>
                                {"Total " + key[0]}
                              </div>
                              <div className={styles.bar}></div>
                              <h3 className={styles.fontData}>
                                {
                                  this.state.dashboardState === true ?
                                    key[1] : <ShimmerBadge />
                                }
                              </h3>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                }
              </div>
            </div>
            <div className='py-4'>
              <div className="row justify-content-between">
                <div className="col-lg-6 m-0 col-sm-12">
                  <div className='col-lg-12 col-sm-12 shadow  bg-white rounded p-2'>
                    <div className=''>
                      <div className=''>
                        <div className='row px-4 '>
                          <div className='col-lg-12 py-4 px-0 '>
                            <h3 className={styles.colorBlue}>Total Institutes</h3>
                          </div>
                          <hr className="hr hr-blurry" />
                          <div className='row m-0 p-0 '>
                            {
                              this.state.isGraphInstitute ?
                                <div id="chart" className='col-12'>
                                  <h1 className={styles.graphDate}>
                                    {this.state.Classes[0].ICOUNT}
                                  </h1>
                                  <p className={styles.graphCount}>
                                    {this.state.Classes[0].IDATE}
                                  </p>
                                  <Chart height={300} width={'100%'} options={this.state.Classes[0].options} series={this.state.Classes[0].series} type="area" chart={this.state.Classes[0].options.chart} />
                                </div>
                                : <div>
                                  <ShimmerThumbnail rounded height={300} />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 m-0 col-sm-12">
                  <div className='col-lg-12 col-sm-12 shadow  bg-white rounded p-2'>
                    <div className=''>
                      <div className=''>
                        <div className='row px-4 '>
                          <div className='col-lg-12 py-4 px-0 '>
                            <h3 className={styles.colorBlue}>Total Teachers</h3>
                          </div>
                          <hr className="hr hr-blurry" />
                          <div className='row m-0 p-0 '>
                            {
                              this.state.isGraphTeacher ?
                                <div id="chart" className='col-12'>
                                  <h1 className={styles.graphDate}>
                                    {this.state.Classes[0].TCOUNT}
                                  </h1>
                                  <p className={styles.graphCount}>
                                    {this.state.Classes[0].TDATE}
                                  </p>
                                  <Chart height={300} width={'100%'} options={this.state.Classes[0].options} series={this.state.Classes[0].series} type="area" chart={this.state.Classes[0].options.chart} />
                                </div>
                                : <div>
                                  <ShimmerThumbnail rounded height={300} />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='py-4'>
              <div className="row justify-content-between">
                <div className="col-lg-6 m-0 col-sm-12">
                  <div className='col-lg-12 col-sm-12 shadow  bg-white rounded p-2'>
                    <div className=''>
                      <div className=''>
                        <div className='row px-4 '>
                          <div className='col-lg-12 py-4 px-0 '>
                            <h3 className={styles.colorBlue}>Total Students</h3>
                          </div>
                          <hr className="hr hr-blurry" />
                          <div className='row m-0 p-0 '>
                            {
                              this.state.isGraphStudent ?
                                <div id="chart" className='col-12'>
                                  <h1 className={styles.graphDate}>
                                    {this.state.Classes[0].SCOUNT}
                                  </h1>
                                  <p className={styles.graphCount}>
                                    {this.state.Classes[0].SDATE}
                                  </p>
                                  <Chart height={300} width={'100%'} options={this.state.Classes[0].options} series={this.state.Classes[0].series} type="area" chart={this.state.Classes[0].options.chart} />
                                </div>
                                : <div>
                                  <ShimmerThumbnail rounded height={300} />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6 m-0 col-sm-12">
                  <div className='col-lg-12 col-sm-12 shadow  bg-white rounded p-2'>
                    <div className=''>
                      <div className=''>
                        <div className='row px-4 '>
                          <div className='col-lg-12 py-4 px-0 '>
                            <h3 className={styles.colorBlue}>Total Classes</h3>
                          </div>
                          <hr className="hr hr-blurry" />
                          <div className='row m-0 p-0 '>
                            {
                              this.state.isGraphClasses ?
                                <div id="chart" className='col-12'>
                                  <h1 className={styles.graphDate}>
                                    {this.state.Classes[0].CCOUNT}
                                  </h1>
                                  <p className={styles.graphCount}>
                                    {this.state.Classes[0].CDATE}
                                  </p>
                                  <Chart height={300} width={'100%'} options={this.state.Classes[0].options} series={this.state.Classes[0].series} type="area" chart={this.state.Classes[0].options.chart} />
                                </div>
                                : <div>
                                  <ShimmerThumbnail rounded height={300} />
                                </div>
                            }
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='row m-0 py-2'>
              <div className='col-lg-12 col-sm-12 shadow  bg-white rounded p-2'>
                <div className='col mx-2 '>
                  <h3 className={styles.topInstitute}>
                    Recently added Institute
                  </h3>
                  <div className={styles.dashboardTable}>
                    {
                      this.state.isDashBoardTable ?
                        <table className="table tableWidth " >
                          <thead className="thead-light">
                            <tr className={styles.tableHeading}>
                              <th scope="col">#</th>
                              <th scope="col">Institute Name</th>
                              <th scope="col">Online / Offline </th>
                              <th scope="col">Number of Teachers</th>
                              <th scope="col">Number of Students</th>
                              <th scope="col">Create Date/Time</th>
                            </tr>
                          </thead>
                          <tbody>
                            {
                              this.state.isDASHBOARDINSTITUTETABLE.map((item, index) => (
                                <tr key={index}>
                                  {console.log('tableDAta==>', index)}
                                  <th>
                                    {index + 1}
                                  </th>
                                  <td>{item.InstituteName}</td>
                                  <td>{item.addressType === 1 ? "Online" : "Offline"}</td>
                                  <td>{item.teacherCount}</td>
                                  <td>{item.studentCount}</td>
                                  {/* <td>{item.createdAt}</td> */}
                                  <td>
                                    {
                                      <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                        {item.createdAt}
                                      </Moment>
                                    }
                                  </td>
                                </tr>
                              ))
                            }
                          </tbody>
                        </table>
                        : <div>
                          <ShimmerThumbnail rounded />
                        </div>
                    }
                  </div>
                </div>
              </div>
            </div>
          </div>
      </div>
    );
  }
}

Dashboard.propTypes = {};

Dashboard.defaultProps = {};

export default Dashboard;
