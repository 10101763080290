import React, { useEffect, useState } from 'react';
// import PropTypes from 'prop-types';
// import styles from './teacherDetails.module.scss';
import Moment from 'react-moment';
import { useNavigate, useParams } from "react-router-dom";
import authHeader from '../../Services/auth-header';
import UserService from '../../Services/user-services';
import AuthService from '../../Services/auth-services';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ShimmerThumbnail } from "react-shimmer-effects-18";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import { LazyLoadImage } from 'react-lazy-load-image-component';
const TeacherDetails = () => {
  const paramsData = useParams();
  const [teacherData, setTeacherData] = useState({
    name: "",
    exp: "",
    mobileNumber: "",
    countryCode: "",
    email: "",
    createdDate: "",
    classes: [],
    subject: [],
    profileLink: "",
    
  })
  const [shimmer, setShimmer] = useState(false)
  const [isActive, setisActive] = useState(0)
  const [classShow, setClassShow] = useState(false)
  const [subjectShow, setSubject] = useState(false)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();
  useEffect(() => {
    const auth = authHeader()
    if (auth == null) {
      return window.location.href = '/Login';
    }
    else {
      let params = {
        id: paramsData.id
      }
      UserService.TeacherDetail(params).then(
        response => {
          console.log("REsponse==>", response)
          if (response.data.status === 200) {
            setTeacherData({
              name: response.data.data.name,
              exp: response.data.data.experience,
              mobileNumber: response.data.data.mobileNumber,
              countryCode: response.data.data.countryCode,
              email: response.data.data.email,
              createdDate: response.data.data.createdAt,
              classes: response.data.data.classRooms,
              subject: response.data.data.Subjects,
              profileLink: response.data.data.profileLink,
             
            })
            setisActive(response.data.data.isActive)
            setShimmer(true)
          }
        },
        error => {
          console.log(error)
          if (error.response.data.status === 401) {
            AuthService.logout();
            return window.location.href = '/Login';
          }
        }
      );

    }
  }, [paramsData.id])
  const classPoupShow = () => {
    setClassShow(true)
  }
  const classPoupClose = () => {
    setClassShow(false)
  }
  const subjectPoupShow = () => {
    setSubject(true)
  }
  const subjectPoupClose = () => {
    setSubject(false)
  }
    const deactivate = ()=> {
    setShow(false)
    
    const  params = {
      id: paramsData.id,
      status:!isActive
    }
      UserService.deacivateTeacherDetails(params).then(
      response => {

        if (response.data.status === 200) {
          setisActive(response.data.data.TeacherDetails.isActive)

        }


      },
      error => {
        console.log(error)
        
      }
    );
  }

  return (
    <div className='main'>
      <div className='d-flex justify-content-between'>
        <div className='detailsHeading'>
          <span className='leftIconHeading' onClick={() => navigate(-1)}><AiOutlineArrowLeft /></span>
          Teacher Details { }
        </div>

      </div>
      {
        shimmer === true ?
          <div className='details'>
            <div className='row justify-content-start'>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                {/* {
                  teacherData.profileLink === "" || teacherData.profileLink === " " || teacherData.profileLink === "0" ? <div><img src='https://petid-gts.s3.amazonaws.com/Others/Others_1663326264541.png' className='mx-4 placeHolderDetail' alt='' /></div> :
                    <div>
                      <img src={'https://topper-dev.s3.amazonaws.com/' + teacherData.profileLink} className='mx-4 placeHolderDetail ' alt='placeHolderDetail' /></div>
                } */}
               
                {
                  <div>
                         <LazyLoadImage
                         key={'https://topper-dev.s3.amazonaws.com/' + teacherData.profileLink}
                         width={'80px'}
                         height={'80px'} 
                         className='clipPart'
                         placeholderSrc={'/imgs/placeholder.svg'}
                         image={'https://topper-dev.s3.amazonaws.com/' + teacherData.profileLink}
                       />
                       </div>
                }
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Teacher Name
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={teacherData.name} ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Total Experience
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={
                  teacherData.exp === 1 ?
                    "None" :
                    teacherData.exp === 2
                      ?
                      "1 year" :
                      teacherData.exp === 3
                        ?
                        "2 to 5 years" :
                        teacherData.exp === 4
                          ?
                          "6 to 10 years" :
                          teacherData.exp === 5
                            ?
                            "11 to 15 years" :

                            "More than 21 years"

                } ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Mobile Number
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={teacherData.countryCode + " " + teacherData.mobileNumber} ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Email Id
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={teacherData.email} ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Joining Date
                </label>
                <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false} className="form-control txtBoxColor">
                  {teacherData.createdDate === "" ? new Date() : teacherData.createdDate}
                </Moment>
                {/* <input type={"text"} className="form-control txtBoxColor" readOnly value={teacherData.createdDate} ></input> */}
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Assign Classes
                </label>
                <span className={'viewmoreList'}>
                  {
                    teacherData.classes.length >= 2 ?
                      <span className='viewList' onClick={classPoupShow}>
                        View more
                      </span> : ""
                  }

                  <input type={"text"} className="form-control txtBoxColor " readOnly value={teacherData.classes.length === 0 ? "N/A" : teacherData.classes.length === 1 ? teacherData.classes[0] : teacherData.classes[0]} ></input>
                </span>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Assign Subject
                </label>
                <span className={'viewmoreList'}>
                  {
                    teacherData.subject.length >= 2 ?
                      <span className='viewList' onClick={subjectPoupShow}>
                        View more
                      </span> : ""
                  }
                  <input type={"text"} className="form-control txtBoxColor " readOnly value={teacherData.subject.length === 0 ? "N/A" : teacherData.subject.length === 1 ? teacherData.subject[0] : teacherData.subject[0]} ></input>
                </span>
              </div>
              <div className='d-flex justify-content-center pt-5'>
                
                  {
                    isActive === 1 ? <button className={"btnEditActivate"} onClick={handleShow}>
                      ACTIVATE
                    </button>
                      :
                      <button className={"btnEditDeactivate"} onClick={handleShow}>
                        DEACTIVATE
                      </button>
                  }
              
              </div>
            </div>
          </div>
          : <ShimmerThumbnail />
      }
      <Modal show={classShow} onHide={classPoupClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>Class List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {
              teacherData.classes.map((item, index) => (
                <li key={index}>
                  {item}
                </li>
              )
              )
            }
          </ol>
        </Modal.Body>
      </Modal>
      {/* Subject Popup */}
      <Modal show={subjectShow} onHide={subjectPoupClose}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='text-center'>Subject List</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <ol>
            {
              teacherData.subject.map((item, index) => (
                <li key={index}>
                  {item}
                </li>
              )
              )
            }
          </ol>
        </Modal.Body>
      </Modal>
      <Modal show={show} onHide={handleClose}

        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure Disable</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deactivate}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

TeacherDetails.propTypes = {};

TeacherDetails.defaultProps = {};

export default TeacherDetails;
