import React, { Component } from "react";
// import PropTypes from 'prop-types';
import styles from "./Subject.module.scss";
import Paper from "@mui/material/Paper";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Moment from "react-moment";
import { BsFilter } from "react-icons/bs";
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import Modal from "react-bootstrap/Modal";
import UserService from "../../Services/user-services";
import authHeader from "../../Services/auth-header";
import AuthService from "../../Services/auth-services";
import { ShimmerThumbnail } from "react-shimmer-effects-18";
import Checkbox from "@mui/material/Checkbox";
import Button from "react-bootstrap/Button";
import { AiFillDelete } from "react-icons/ai";
class Subject extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 5,
      totalPages: 0,
      searchValue: "",
      Subjects: [],
      instituteList: [],
      subjectDisableshow: false,
      subjectDelete: false,
      isSujectDisable: false,
      id: 0,
      isDisableDeletestatus: "",
      show: false,
      timeFilter: 0,
      instituteId: "0",
      isClassesShimmer: false,
      selectedValue: "",
      showPopup: false,
      filterData: [],
      shimmerPop: false,
    };
  }
  componentDidMount() {
    const auth = authHeader();
    if (auth == null) {
      return (window.location.href = "/Login");
    } else {
      let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
      };
      UserService.subjectList(params).then(
        (response) => {
          console.log("response==>", response);
          if (response.data.status === 200) {
            this.setState(
              {
                Subjects: response.data.data.Subjects,
              },
              () => {
                this.state.Subjects.forEach((e) => {
                  e.isSelected = false;
                });
                console.log("Message==>", this.state.Subjects);
                this.setState(
                  {
                    isClassesShimmer: true,
                    totalRecords: response.data.data.totalRecords,
                    pageIndex: response.data.data.pageIndex,
                    totalPages: response.data.data.totalPages,
                  },
                  () => {
                    console.log("STate==>", this.state);
                  }
                );
              }
            );
          }
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
      let instituteParmas = {
        pageSize: "0",
        instituteId: this.state.instituteId,
      };
      UserService.InstiteList(instituteParmas).then(
        (response) => {
          console.log("response==>", response);
          if (response.data.status === 200) {
            this.setState({
              instituteList: response.data.data.InstituteList,
            });
          }
        },
        (error) => {
          console.log(error);
          // AuthService.logout();
          // return window.location.href = '/Login';
        }
      );
    }
  }
  handleChangeRowsPerPage = (e, value) => {
    this.setState({
      pageIndex: value,
    });
  };
  handleChangePage = (e, value) => {
    this.setState({
      pageIndex: 0,
    });
  };
  handleChange = (e, value) => {
    console.log("ValueOfE==>", e, value);
    this.setState(
      {
        pageIndex: value,
      },
      () => {
        let params = {
          searchKey: this.state.searchValue,
          timeFilter: parseInt(this.state.timeFilter),
          pageIndex: this.state.pageIndex,
          pageSize: this.state.pageSize,
        };
        UserService.subjectList(params).then(
          (response) => {
            console.log("response==>", response);
            if (response.data.status === 200) {
              this.setState(
                {
                  Subjects: response.data.data.Subjects,
                },
                () => {
                  this.setState({
                    isClassesShimmer: true,
                    totalRecords: response.data.data.totalRecords,
                    pageIndex: response.data.data.pageIndex,
                    totalPages: response.data.data.totalPages,
                  });
                }
              );
            }
          },
          (error) => {
            console.log(error);
            AuthService.logout();
            return (window.location.href = "/Login");
          }
        );
      }
    );
  };
  filterPopup = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  handleChangeDropDown = (e) => {
    this.setState(
      {
        timeFilter: e.target.value,
      },
      () => {
        let params = {
          searchKey: this.state.searchValue,
          timeFilter: parseInt(this.state.timeFilter),
          pageIndex: 1,
          pageSize: this.state.pageSize,
        };
        UserService.subjectList(params).then(
          (response) => {
            console.log("response==>", response);
            if (response.data.status === 200) {
              this.setState(
                {
                  Subjects: response.data.data.Subjects,
                },
                () => {
                  this.setState({
                    isClassesShimmer: true,
                    totalRecords: response.data.data.totalRecords,
                    pageIndex: response.data.data.pageIndex,
                  });
                }
              );
            }
          },
          (error) => {
            console.log(error);
            AuthService.logout();
            return (window.location.href = "/Login");
          }
        );
      }
    );
  };
  searchValue = (event) => {
    this.setState(
      {
        searchValue: event.target.value,
        pageIndex: 1,
      },
      () => {}
    );
    !!this.timeout && clearTimeout(this.timeout);
    this.timeout = setTimeout(() => {
      let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: 1,
        pageSize: this.state.pageSize,
      };
      UserService.subjectList(params).then(
        (response) => {
          console.log("response==>", response);
          if (response.data.status === 200) {
            this.setState(
              {
                Subjects: response.data.data.Subjects,
              },
              () => {
                this.setState({
                  isClassesShimmer: true,
                  totalRecords: response.data.data.totalRecords,
                  pageIndex: response.data.data.pageIndex,
                });
              }
            );
          }
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
    }, 800);
  };
  handleChangeDropDownClasses = (e) => {
    this.setState(
      {
        instituteId: e.target.value,
      },
      () => {
        let params = {
          searchKey: this.state.searchValue,
          timeFilter: parseInt(this.state.timeFilter),
          pageIndex: 1,
          pageSize: this.state.pageSize,
          instituteId: e.target.value,
        };
        UserService.subjectList(params).then(
          (response) => {
            console.log("response==>", response);
            if (response.data.status === 200) {
              this.setState(
                {
                  Subjects: response.data.data.Subjects,
                },
                () => {
                  this.setState({
                    isClassesShimmer: true,
                    totalRecords: response.data.data.totalRecords,
                    pageIndex: response.data.data.pageIndex,
                  });
                }
              );
            }
          },
          (error) => {
            console.log(error);
            AuthService.logout();
            return (window.location.href = "/Login");
          }
        );
        console.log("e==>", e.target.value);
      }
    );
  };
  handleChangeSelected = (e) => {
    console.log(
      "Value of e==>",
      e,
      "FilterArray==>",
      this.state.filterData.length
    );
    this.setState((prevState) => ({
      Subjects: prevState.Subjects.map((obj) =>
        obj.id === e ? Object.assign(obj, { isSelected: !obj.isSelected }) : obj
      ),
    }));
    this.setState({
      filterData: this.state.filterData.filter((item) => {
        return item.isSelected;
      }),
    });
  };
  handleChangeSelectedPopup = (e) => {
    this.setState(
      {
        shimmerPop: false,
      },
      () => {
        this.setState(
          (prevState) => ({
            Subjects: prevState.Subjects.map((obj) =>
              obj.id === e
                ? Object.assign(obj, { isSelected: !obj.isSelected })
                : obj
            ),
          }),
          () => {
            this.setState(
              {
                filterData: this.state.filterData.filter((item) => {
                  return item.isSelected;
                }),
              },
              () => {
                this.setState({
                  shimmerPop: true,
                });
              }
            );
          }
        );
      }
    );
    console.log(
      "Value of e==>",
      e,
      "FilterArray==>",
      this.state.filterData.length
    );
  };
  MergeData = () => {
    console.log("SelectedData==>", this.state.filterData.length);
    let data = this.state.Subjects.filter(function (item) {
      return item.isSelected === true;
    }).map(function (e) {
      return e.id;
    });
    let text = data.join(",");
    console.log("SelectedData==>", text);
  };
  handleClickOpen = () => {
    this.setState({
      showPopup: true,
      shimmerPop: true,
      filterData: this.state.Subjects.filter((item) => {
        return item.isSelected;
      }),
    });
  };
  handleClickClose = () => {
    this.setState({
      showPopup: false,
    });
  };
  subjectDisableShowHandleClose = () =>
    this.setState({ subjectDisableshow: false });
  subjectDisableShowHandleShow = (e, status) => {
    this.setState({
      id: e,
      isDisableDeletestatus: !status,
    });
    // console.log("Parmas-==>",e)
    this.setState({ subjectDisableshow: true });
  };
  subjectDeleteShowHandleClose = () => this.setState({ subjectDelete: false });
  subjectDeleteShowHandleShow = (e) =>
    this.setState({
      subjectDelete: true,
      id: e,
    });
  subjectDisableApi = () => {
    let Parmas = {
      id: this.state.id,
      status: this.state.isDisableDeletestatus,
    };
    UserService.subjectDetailsDisbale(Parmas).then(
      (response) => {
        if (response.data.status === 200) {
          this.setState(
            {
              currentPage: 1,
              recordPerPage: 10,
              search: "",
              subjectDisableshow: false,
            },
            () => {
              let params = {
                searchKey: this.state.searchValue,
                timeFilter: parseInt(this.state.timeFilter),
                pageIndex: this.state.pageIndex,
                pageSize: this.state.pageSize,
              };
              UserService.subjectList(params).then(
                (response) => {
                  console.log("response==>", response);
                  if (response.data.status === 200) {
                    this.setState(
                      {
                        Subjects: response.data.data.Subjects,
                      },
                      () => {
                        this.setState({
                          totalRecords: response.data.data.totalRecords,

                          recordPerPage: response.data.data.pageSize,
                        });
                      }
                    );
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };

  subjectDeleteApi = () => {
    let Parmas = {
      id: this.state.id,
    };
    this.setState({
      subjectDelete: false,
    });
    console.log(Parmas);
    UserService.subjectDetailsDelete(Parmas).then(
      (response) => {
        if (response.data.status === 200) {
          this.setState(
            {
              currentPage: 1,
              recordPerPage: 10,
              search: "",
              subjectDisableshow: false,
            },
            () => {
              let params = {
                searchKey: this.state.searchValue,
                timeFilter: parseInt(this.state.timeFilter),
                pageIndex: this.state.pageIndex,
                pageSize: this.state.pageSize,
              };
              UserService.subjectList(params).then(
                (response) => {
                  console.log("response==>", response);
                  if (response.data.status === 200) {
                    this.setState({
                      Subjects: response.data.data.Subjects,
                      totalRecords: response.data.data.totalRecords,
                      recordPerPage: response.data.data.pageSize,
                    });
                  }
                },
                (error) => {
                  console.log(error);
                }
              );
            }
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  };
  render() {
    return (
      <div className="main">
        <div className="table-heading d-flex justify-content-between flex-wrap">
          <div className="tabing-heading-name">Subject Management</div>
          <div className="tableFilterColumn">
            <div
              className={`d-flex justify-content-between flex-wrap tableFilter`}
            >
              <div className="px-3">
                <input
                  type="text"
                  className="form-control searching"
                  placeholder="Search"
                  onChange={this.searchValue}
                ></input>
              </div>
              <div className="px-3">
                <button
                  className="btn btn-success btn-topper filter  position-relative"
                  onClick={this.filterPopup}
                >
                  <span>
                    <BsFilter className="filterIcon" />
                  </span>
                  Filter
                </button>
              </div>
              <div className="px-3">
                <button className="btn btn-success btn-topper  export">
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {this.state.show === false ? (
          ""
        ) : (
          <div className="row pt-5">
            <div className="col-lg-1 col-sm-12 ">
              <button className="btn btn-topper" onClick={this.handleClickOpen}>
                Merger
              </button>
            </div>
            <div className="col-lg-1 col-sm-12 ">
              <button className="btn btn-topper" onClick={this.MergeData}>
                Cancel
              </button>
            </div>
          </div>
        )}
        <span className="line"></span>
        <div>
          {this.state.isClassesShimmer === true ? (
            <div>
              <div className="py-5">
                <TableContainer component={Paper}>
                  <Table
                    sx={{ minWidth: 650 }}
                    size="small"
                    aria-label="a dense table"
                  >
                    <TableHead>
                      <TableRow>
                        {this.state.show === true ? (
                          <TableCell style={{ fontWeight: "bold" }}></TableCell>
                        ) : (
                          ""
                        )}
                        <TableCell style={{ fontWeight: "bold" }}>#</TableCell>
                        <TableCell style={{ fontWeight: "bold" }}>
                          Name
                        </TableCell>

                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Created Date
                        </TableCell>
                        <TableCell style={{ fontWeight: "bold" }} align="left">
                          Actions{" "}
                        </TableCell>
                      </TableRow>
                    </TableHead>
                    <TableBody>
                      {this.state.Subjects.map((row, index) => (
                        <TableRow
                          key={index}
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {this.state.show === true ? (
                            <TableCell align="left">
                              <Checkbox
                                checked={row.isSelected}
                                onChange={() => {
                                  this.handleChangeSelected(row.id);
                                }}
                              />
                            </TableCell>
                          ) : (
                            ""
                          )}

                          <TableCell align="left">{index + 1}</TableCell>
                          <TableCell align="left">{row.title}</TableCell>

                          <TableCell align="left">
                            {
                              <Moment
                                format="YYYY-MMM-DD | HH:mm:ss A"
                                utc={false}
                              >
                                {row.createdAt}
                              </Moment>
                            }
                          </TableCell>
                          <TableCell align="left">
                            {row.isDisable === 0 ? (
                              <div className={styles.borderDanger}>
                                <img
                                  src="/imgs/block.png"
                                  className={styles.blockIconWidtht}
                                  alt="blockIcon"
                                  onClick={() => {
                                    console.log("Called==>", row);
                                    this.subjectDisableShowHandleShow(
                                      row.id,
                                      row.isDisable
                                    );
                                  }}
                                ></img>
                              </div>
                            ) : (
                              <div className={styles.borderPrimary}>
                                <img
                                  src="/imgs/block.png"
                                  className={styles.blockIconWidtht}
                                  alt="blockIcon"
                                  onClick={() => {
                                    console.log("Called==>", row);
                                    this.subjectDisableShowHandleShow(
                                      row.id,
                                      row.isDisable
                                    );
                                  }}
                                ></img>
                              </div>
                            )}
                            <div className={styles.deleteIcon}>
                              <img
                                src="/imgs/delete_icon.png"
                                className={styles.delteIconWidtht}
                                alt="blockIcon"
                                onClick={() => {
                                  this.subjectDeleteShowHandleShow(row.id);
                                }}
                              ></img>
                            </div>
                          </TableCell>
                        </TableRow>
                      ))}
                    </TableBody>
                  </Table>
                </TableContainer>
              </div>
              <div className="d-flex justify-content-center">
                {this.state.totalRecords !== 0 ? (
                  <Stack spacing={2}>
                    <Pagination
                      color="primary"
                      count={this.state.totalPages}
                      page={this.state.pageIndex}
                      onChange={this.handleChange}
                    />
                  </Stack>
                ) : (
                  "No Data found"
                )}
              </div>
            </div>
          ) : (
            <ShimmerThumbnail rounded />
          )}
        </div>
        <div>
          <Modal
            show={this.state.showPopup}
            onHide={this.handleClickClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Subject Merge</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div className="pb-3">
                <label>Subject</label>
              </div>
              <div>
                <span className="mt-5">
                  <input type="text" className="form-control"></input>
                </span>
              </div>
              <div className="pt-5 text-center">
                {this.state.shimmerPop === true ? (
                  this.state.filterData.length === 0 ? (
                    <span className="w-100">No Data found</span>
                  ) : (
                    <TableContainer component={Paper}>
                      <Table sx={{}} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }}>
                              #
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Name
                            </TableCell>
                            <TableCell style={{ fontWeight: "bold" }}>
                              Action
                            </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {this.state.filterData.map((row, index) => (
                            <TableRow
                              key={index}
                              sx={{
                                "&:last-child td, &:last-child th": {
                                  border: 0,
                                },
                              }}
                            >
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align="left">{row.title}</TableCell>
                              <TableCell align="left">
                                <AiFillDelete
                                  onClick={() => {
                                    this.handleChangeSelectedPopup(row.id);
                                  }}
                                  style={{
                                    fontSize: "20px",
                                    cursor: "pointer",
                                  }}
                                  className="text-danger"
                                />
                              </TableCell>
                            </TableRow>
                          ))}
                        </TableBody>
                      </Table>
                    </TableContainer>
                  )
                ) : (
                  <ShimmerThumbnail />
                )}
              </div>
            </Modal.Body>

            <Modal.Footer>
              <Button variant="danger" onClick={this.handleClickClose}>
                Cancel
              </Button>
              <Button
                variant="primary"
                disabled={this.state.filterData.length === 0 ? true : false}
                className={
                  this.state.filterData.length === 0
                    ? "opacity-25"
                    : "opacity-100"
                }
              >
                Ok
              </Button>
            </Modal.Footer>
          </Modal>

          <Modal
            show={this.state.subjectDisableshow}
            onHide={this.subjectDisableShowHandleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Are You Sure Disable</Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={this.subjectDisableShowHandleClose}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={this.subjectDisableApi}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
          <Modal
            show={this.state.subjectDelete}
            onHide={this.subjectDeleteShowHandleClose}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
          >
            <Modal.Header closeButton>
              <Modal.Title>Are You Sure Delete</Modal.Title>
            </Modal.Header>

            <Modal.Footer>
              <Button
                variant="danger"
                onClick={this.subjectDeleteShowHandleClose}
              >
                Cancel
              </Button>
              <Button variant="primary" onClick={this.subjectDeleteApi}>
                Ok
              </Button>
            </Modal.Footer>
          </Modal>
        </div>
      </div>
    );
  }
}

Subject.propTypes = {};

Subject.defaultProps = {};

export default Subject;
