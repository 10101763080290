import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import { BrowserRouter, Routes, Route } from "react-router-dom";
// import Login from './components/login/login';

import Login from './components/Login/Login';
import Dashboard from './components/Dashboard/Dashboard';
import Institute from "./components/Institute/Institute"
import Layout from './components/Layout/Layout';
import Teacher from './components/Teacher/Teacher';
import Student from './components/Student/Student';
import Classes from './components/Classes/Classes';
import Subject from './components/Subject/Subject';
import Studymatarial from './components/StudyMatarial/StudyMatarial';
import Notfication from './components/Notfication/Notfication';
import Pages from './components/Pages/Pages';
import Setting from './components/Setting/Setting';
import TeacherDetails from './components/TeacherDetails/TeacherDetails';
import StudentDetails from './components/StudentDetails/StudentDetails';
import ClassesDetails from './components/classesDetails/classesDetails';
import InstituteDetails from './components/InstituteDetails/InstituteDetails';
import Studymatarialdetails from './components/studymatarialdetails/studymatarialdetails';

function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route path="/Login" element={<Login />}></Route>
      <Route path='/' element={<Layout />}>
        <Route path='/' index element={<Dashboard />} />
        <Route path='/dashboard' index element={<Dashboard />} />
        <Route path='/institute' index element={<Institute />} />
         <Route path='/teacher' index element={<Teacher />} />
         <Route path='/student' index element={<Student />} />
         <Route path='/class' index element={<Classes />} />
         <Route path='/subject' index element={<Subject />} />
         <Route path='/study-matarial' index element={<Studymatarial />} />
         <Route path='/notfication' index element={<Notfication />} />
         <Route path='/pages' index element={<Pages />} /> 
         <Route path='/setting' index element={<Setting />} /> 
         <Route path='/teacher/teacher-detail/:id' index element={<TeacherDetails />} /> 
         <Route path='/student/student-detail/:id' index element={<StudentDetails />} /> 
         <Route path='/class/class-detail/:id' index element={<ClassesDetails />} /> 
         <Route path='/institute/institute-detail/:id' index element={<InstituteDetails />} /> 
         <Route path='/study-matarial/study-matarial-detail/:id' index element={<Studymatarialdetails />} /> 
        <Route path='/*' index element={<Dashboard />} />

    

      </Route>
    </Routes>

  </BrowserRouter>
  );
}

export default App;
