
export default function authHeader() {
  // console.log = () => {}
  const user = localStorage.getItem('token');
  console.log('token==>',user)
  if (user) {
    console.log("Token")
    return { Authorization:user }; 
  } else {
    return null;

  }
}
