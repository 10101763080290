import React, { Component } from "react";
// import PropTypes from 'prop-types';
import styles from "./Pages.module.scss";
import { Tab, Tabs } from "react-bootstrap";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import UserService from "../../Services/user-services";
class Pages extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      tabKey: "one",
      name: "React",
      data: "",
      dataValue: "",
      id: 0,
      sucessPopup: false,
      sucessClass: true,
      enumType: 1,
      title: "",
    };
    this.handleChange = this.handleChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }
  componentDidMount() {
    let params = {
      enumType: this.state.enumType,
    };
    UserService.Privacy(params).then(
      (response) => {
        console.log(response);
        if (response.data.status === 200) {
          this.setState(
            {
              dataValue: response.data.data.contentText,
              id: response.data.data.id,
              title: response.data.data.title,
            },

            this.setState(
              {
                loader: false,
              },
              () => {
                console.log("Value==>", this.state.value);
              }
            )
          );
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  handleChange(html) {
    this.setState({
      dataValue: html,
    });
  }
  handleSubmit() {
    let params = {
      enumType: this.state.Privacy,
      contentText: this.state.value,
    };
    UserService.EditPrivacy(params).then(
      (response) => {
        console.log("submit==>", response);
        if (response.data.status === 200) {
          this.setState({
            sucessClass: true,
            message: response.data.message,
            sucessPopup: true,
          });
        }
      },
      (error) => {
        console.log(error);
      }
    );
  }
  pages = (e) => {
    console.log("VAlueOf==>", e);
    if (e === 1) {
      this.setState(
        {
          enumType: e,
        },
        () => {
          let params = {
            enumType: this.state.enumType,
          };
          UserService.Privacy(params).then(
            (response) => {
              console.log(response);
              if (response.data.status === 200) {
                this.setState(
                  {
                    dataValue: response.data.data.contentText,
                    id: response.data.data.id,
                    title: response.data.data.title,
                  },

                  this.setState(
                    {
                      loader: false,
                    },
                    () => {
                      console.log("Value==>", this.state.value);
                    }
                  )
                );
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      );
    } else if (e === 2) {
      this.setState(
        {
          enumType: e,
        },
        () => {
          let params = {
            enumType: this.state.enumType,
          };
          UserService.Privacy(params).then(
            (response) => {
              console.log(response);
              if (response.data.status === 200) {
                this.setState(
                  {
                    dataValue: response.data.data.contentText,
                    title: response.data.data.title,
                    id: response.data.data.id,
                  },

                  this.setState(
                    {
                      loader: false,
                    },
                    () => {
                      console.log("Value==>", this.state.value);
                    }
                  )
                );
              }
            },
            (error) => {
              console.log(error);
            }
          );
        }
      );
    }
  };
  render() {
    return (
      <div className="main">
        <div className="table-heading d-flex justify-content-between flex-wrap">
          <div className="tabing-heading-name">Page Content</div>
        </div>
        <div className="pt-3">
          <Tabs
            activeKey={this.state.tabKey}
            onSelect={(e) =>
              this.setState({
                tabKey: e,
              })
            }
          >
            <Tab eventKey="one" title="Privacy Policy">
              <div className="py-3">
                <ReactQuill
                  onChange={this.handleChange}
                  className={styles.height}
                  value={this.state.dataValue}
                  scrollingContainer=".parent-scroll"
                />
              </div>
              <div className={styles.privacyBtn}>
                <button className="btn btn-success" onClick={this.handleSubmit}>
                  Submit
                </button>
              </div>
            </Tab>
            <Tab eventKey="two" title="Terms and condition">
              <div className="py-3">
                <ReactQuill
                  onChange={this.handleChange}
                  className={styles.height}
                  value={this.state.dataValue}
                  scrollingContainer=".parent-scroll"
                />
              </div>
              <div className={styles.privacyBtn}>
                <button className="btn btn-success" onClick={this.handleSubmit}>
                  Submit
                </button>
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    );
  }
}

Pages.propTypes = {};

Pages.defaultProps = {};

export default Pages;
