import React, { useEffect, useState } from 'react';
// import styles from './studentDetails.module.scss';
// import PropTypes from 'prop-types';
// import styles from './teacherDetails.module.scss';
import Moment from 'react-moment';
import { useNavigate, useParams } from "react-router-dom";
import authHeader from '../../Services/auth-header';
import UserService from '../../Services/user-services';
import AuthService from '../../Services/auth-services';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ShimmerThumbnail } from "react-shimmer-effects-18";
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
const StudentDetails = () => {
  const paramsData = useParams();
  const [studnetData, setstudnetData] = useState({
    name: "",
    exp: "",
    mobileNumber: "",
    countryCode: "",
    email: "",
    createdDate: "",
    classes: "",
    subject: "",
    profileLink: "",
    
  })
  const [shimmer, setShimmer] = useState(false)
  const [isActive, setisActive] = useState(0)
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  let navigate = useNavigate();
  useEffect(() => {
    const auth = authHeader()
    if (auth == null) {
      return window.location.href = '/Login';
    }
    else {
      let params = {
        id: paramsData.id
      }
      
      UserService.StudentDetail(params).then(
        response => {
          console.log("REsponse==>", response)
          if (response.data.status === 200) {
            setstudnetData({
              name: response.data.data.name,
              exp: response.data.data.experience,
              mobileNumber: response.data.data.mobileNumber,
              countryCode: response.data.data.countryCode,
              email: response.data.data.email,
              createdDate: response.data.data.createdAt,
              classes: response.data.data.className,
              subject: response.data.data.subjects,
              profileLink: response.data.data.profileLink,
             
            })
            setisActive(response.data.data.isActive)
            setShimmer(true)
          }
        },
        error => {
          console.log(error)
          if (error.response.data.status === 401) {
            AuthService.logout();
            return window.location.href = '/Login';
          }
        }
      );

    }
  }, [paramsData.id])
    const deactivate = ()=> {
    setShow(false)
    
    const  params = {
      id: paramsData.id,
      status:!isActive
    }
      UserService.deacivateStudentDetails(params).then(
      response => {
        console.log(response)
        if (response.data.status === 200) {
          setisActive(response.data.data.studentDetail.isActive)

        }


      },
      error => {
        console.log(error)
        
      }
    );
  }

  return (
    <div className='main'>
      <div className='d-flex justify-content-between'>
        <div className='detailsHeading'>
          <span className='leftIconHeading' onClick={() => navigate(-1)}><AiOutlineArrowLeft /></span>
          Student Details { }
        </div>

      </div>
      {
        shimmer === true ?
          <div className='details'>
            <div className='row justify-content-start'>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                {
                  studnetData.profileLink === "" || studnetData.profileLink === " " || studnetData.profileLink === "0" ? <div><img src='https://petid-gts.s3.amazonaws.com/Others/Others_1663326264541.png' className='mx-4 placeHolderDetail' alt='' /></div> :
                    <div><img src={'https://topper-dev.s3.amazonaws.com/' + studnetData.profileLink} className='mx-4 placeHolderDetail ' alt='placeHolderDetail' /></div>
                }
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Name
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={studnetData.name} ></input>
              </div>
             
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Mobile Number
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={studnetData.countryCode + " " + studnetData.mobileNumber} ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Email Id
                </label>
                <input type={"text"} className="form-control txtBoxColor" readOnly value={studnetData.email} ></input>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Joining Date
                </label>
                <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false} className="form-control txtBoxColor">
                  {studnetData.createdDate === "" ? new Date() : studnetData.createdDate}
                </Moment>
                {/* <input type={"text"} className="form-control txtBoxColor" readOnly value={studnetData.createdDate} ></input> */}
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                   Classes
                </label>
                <span className={'viewmoreList'}>
                  

                  <input type={"text"} className="form-control txtBoxColor " readOnly value={studnetData.classes === null ? "N/A" : studnetData.classes} ></input>
                </span>
              </div>
              <div className='col-md-4 m-0 pt-3 col-sm-12'>
                <label className='detailTitle'>
                  Subject
                </label>
                <span className={'viewmoreList'}>
                 
                  <input type={"text"} className="form-control txtBoxColor " readOnly value={studnetData.subject === null ? "N/A" : studnetData.subject} ></input>
                </span>
              </div>
              <div className='d-flex justify-content-center pt-5'>
                
                  {
                    isActive === 1 ? <button className={"btnEditActivate"} onClick={handleShow}>
                      ACTIVATE
                    </button>
                      :
                      <button className={"btnEditDeactivate"} onClick={handleShow}>
                        DEACTIVATE
                      </button>
                  }
              
              </div>
            </div>
          </div>
          : <ShimmerThumbnail />
      }

      <Modal show={show} onHide={handleClose}

        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title>Are You Sure Disable</Modal.Title>
        </Modal.Header>

        <Modal.Footer>
          <Button variant="danger" onClick={handleClose}>
            Cancel
          </Button>
          <Button variant="primary" onClick={deactivate}>
            Ok
          </Button>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

StudentDetails.propTypes = {};

StudentDetails.defaultProps = {};

export default StudentDetails;
