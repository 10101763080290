import React,{Component} from 'react';
// import PropTypes from 'prop-types';
// import styles from './instituteDetails.module.scss';
import authHeader from '../../Services/auth-header';
import UserService from '../../Services/user-services';
import AuthService from '../../Services/auth-services';
import { AiOutlineArrowLeft } from 'react-icons/ai'
import { ShimmerThumbnail } from "react-shimmer-effects-18";
// import Modal from 'react-bootstrap/Modal';
// import Button from 'react-bootstrap/Button';
import withRouter from '../../Common/with-router';
import Moment from 'react-moment'
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import { AiFillEye } from 'react-icons/ai'
import { Link } from 'react-router-dom';
class InstituteDetails  extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      shimmer: false,
      name: "N/A",
      addressType: 0,
      teacherCount: 0,
      studentCount: 0,
      createdDate: "",
      institudeAbout:"",
      isActive: false,
      studyMatarialSearching: "",
      teacherSearching: "",
      studentSearching: "",
      batchSearching:"",
      isTeachersShimmer: false,
      teacherTable: [],
      teacherPageIndex: 1,
      teacherPageSize: 10,
      teacherTotalRecords: 0,
      teacherTotalPages: 1,
      isStudentShimmer:false,
      studentTable: [],
      studentPageIndex: 1,
      studentPageSize: 10,
      studentTotalRecords: 0,
      studentTotalPages: 1,
      isStudyMatarialShimmer:false,
      studyMatarialTable : [],
      studyMatarialPageIndex: 1,
      studyMatarialPageSize: 10,
      studyMatarialTotalRecords: 0,
      studyMatarialTotalPages: 1,
      isBatchesShimmer:true,
      isBatchTable : [],
      isBatchPageIndex: 1,
      isBatchPageSize: 10,
      isBatchTotalRecords: 0,
      isBatchTotalPages: 1,
      isChecked:false

    
    }
  }
  componentDidMount() {
    const auth = authHeader()
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      console.log("this.props==>",)
      let params = {
        id: this.props.params.id,
        
      }
    
      UserService.InstiteDetail(params).then(
        response => {
          if (response.data.status === 200) {
            this.setState({
              name: response.data.data.InstituteName,
              addressType: response.data.data.addressType === 1 ? "Online" : "Offline",
              teacherCount: response.data.data.teacherCount,
              studentCount:response.data.data.studentCount,
              institudeAbout:response.data.data.institudeAbout,
              isActive: response.data.data.isActive,
              createdDate: response.data.data.createdAt,
              // studyMatarialTable:response.data.data.classSubject
            },
              () => {
                this.setState({
                  shimmer: true
                })
              }
            )
          }
        },
        error => {
          console.log(error)
          if (error.response.status === 401) {
            AuthService.logout();
            return window.location.href = '/Login';
          }
        }
      )
      let teacherParams = {
        searchKey: this.state.teacherSearching,
        pageIndex: this.state.teacherPageIndex,
        pageSize: this.state.teacherPageSize,
        id: this.props.params.id
      }
      UserService.teacherListDetail(teacherParams).then(
        response => {
          if (response.data.status === 200) {
            if(response.data.status === 200){
              this.setState({
                teacherTable:response.data.data.teacherList,
                teacherPageIndex: response.data.data.pageIndex,
                teacherPageSize: response.data.data.pageSize,
                teacherTotalRecords: response.data.data.totalRecords,
                teacherTotalPages: response.data.data.totalPages
              },()=>{
                this.setState({
                  isTeachersShimmer:true
                })
              })
            }
            
          }
        },
        error => {
          console.log(error);
        }
      );
      let studentParams = {
        searchKey: this.state.studentSearching,
        pageIndex: this.state.studentPageIndex,
        pageSize: this.state.studentPageSize,
        id: this.props.params.id
      }
      UserService.studentListDetail(studentParams).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {

           this.setState({
            studentTable:response.data.data.studentList,
            studentTotalPages:response.data.data.totalPages,
            studentTotalRecords:response.data.data.totalRecords,
            studentPageSize:response.data.data.pageSize,
            studentPageIndex:response.data.data.pageIndex
           },
           ()=>{
            this.setState({
              isStudentShimmer:true
            })
           })


          }

        },
        error => {
          console.log(error);

        }
      );
      let StudyMatarialParmas = {
        searchKey: this.state.studyMatarialSearching,
        pageIndex: this.state.studyMatarialPageIndex,
        pageSize: this.state.studyMatarialPageSize,
        id: this.props.params.id
      }
      UserService.StudyMatrialInstitute(StudyMatarialParmas).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {

           this.setState({
            studyMatarialTable:response.data.data.studyMatarialList,
            studyMatarialTotalPages:response.data.data.totalPages,
            studyMatarialTotalRecords:response.data.data.totalRecords,
            studyMatarialPageSize:response.data.data.pageSize,
            studentPageIndex:response.data.data.pageIndex
           },
           ()=>{
            this.setState({
              isStudyMatarialShimmer:true
            })
           })


          }

        },
        error => {
          console.log(error);

        }
      );
      let BatchesParams = {
        instituteId:this.props.params.id,
        pageIndex:this.state.isBatchPageIndex,
        pageSize:this.state.isBatchPageSize,
        searchKey:this.state.batchSearching
      }
      UserService.classesList(BatchesParams).then(
        response => {
          console.log("response==>", response)
          if (response.data.status === 200) {

         


          }

        },
        error => {
          console.log(error);

        }
      );
    }
  }
  handleShow = () => {

  }
  toggleChange = (event)=>{
    console.log(event)
    this.setState({
      isChecked:!this.state.isChecked
    })
    let params = {
      id:event.target.value,
      status:!this.state.isChecked
    }
    UserService.classRoomSubjectStatus(params).then(
      response => {

        console.log('response==>', response)


      },
      error => {
        console.log(error)
        
      }
    );
  }
  render() {
    return (
      <div className='main'>
        <div className='d-flex justify-content-between'>
          <div className='detailsHeading'>
            <span className='leftIconHeading' onClick={() => this.props.navigate(-1)}><AiOutlineArrowLeft /></span>
            Institute Details
          </div>

        </div>
        {
          this.state.shimmer === true ?
            <div className='details'>
              <div className='row justify-content-start'>

                <div className='col-md-4 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                    Name
                  </label>
                  <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.name} ></input>
                </div>


                <div className='col-md-4 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                Address Mode
                  </label>
                  <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.addressType} ></input>
                </div>
                <div className='col-md-4 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                    Total Teachers
                  </label>
                  <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.teacherCount} ></input>
                </div>
                <div className='col-md-4 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                    Total Student
                  </label>
                  <input type={"text"} className="form-control txtBoxColor" readOnly value={this.state.studentCount} ></input>
                </div>
                <div className='col-md-4 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                    Created Date
                  </label>
                  <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false} className="form-control txtBoxColor">
                    {this.state.createdDate === "" ? new Date() : this.state.createdDate}
                  </Moment>

                </div>
                <div className='col-md-12 m-0 pt-3 col-sm-12'>
                  <label className='detailTitle'>
                  Institute About
                  </label>
                  <textarea  className="form-control txtBoxColor overflow-hidden" readOnly value={this.state.institudeAbout} rows={5} ></textarea>
                </div>

                <div className='d-flex justify-content-center pt-5'>

                  {
                    this.state.isActive === 1 ? <button className={"btnEditActivate"} onClick={this.handleShow}>
                      ACTIVATE
                    </button>
                      :
                      <button className={"btnEditDeactivate"} onClick={this.handleShow}>
                        DEACTIVATE
                      </button>
                  }

                </div>
              </div>
            </div>
            : <ShimmerThumbnail />
        }
        <div>
          <div className='table-heading d-flex justify-content-between flex-wrap'>
            <div className='tabing-heading-name'>
              Teacher Details
            </div>
          </div>
          <div>
            {
              this.state.isTeachersShimmer === true ?
                <div>
                  <div className='py-3'>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }} >#</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} >Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">	Total Experience</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Mobile Number	</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Email Id</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Date of joining</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Actions </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            this.state.teacherTable.map((row, index) => (
                              <TableRow

                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align='left'>
                                  {row.name}
                                </TableCell>
                                <TableCell align="left">{
                                  row.experience === "0" ? "None" :
                                    row.experience === "1" ? "1 year" :
                                      row.experience === "2" ? "2 to 5 years" :
                                        row.experience === "3" ? "6 to 10 years" :
                                          row.experience === "4" ? "11 to 14 years" :
                                            row.experience === "5" ? "15 to 20 years" :
                                              "More than 21 years"
                                }</TableCell>
                                <TableCell align="left">{row.countryCode + " " + row.mobileNumber}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{
                                  <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                    {row.createdAt}
                                  </Moment>
                                }</TableCell>
                                <TableCell align="left">
                                  <Link to={'/teacher/teacher-detail/' + row.id}>
                                    <AiFillEye className='detailIcon' />
                                  </Link>

                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className='d-flex justify-content-center'>
                    {
                      this.state.teacherTotalRecords !== 0 ?
                        <Stack spacing={2}>

                          <Pagination color="primary" count={this.state.teacherTotalPages} page={this.state.teacherPageIndex} onChange={this.teacherHandleChange} />
                        </Stack>
                        : "No Data found"
                    }

                  </div>
                </div>
                :
                <ShimmerThumbnail rounded />
            }
          </div>
        </div>
        <div>
          <div className='table-heading d-flex justify-content-between flex-wrap'>
            <div className='tabing-heading-name'>
              Student Details
            </div>
          </div>
          <div>
            {
              this.state.isStudentShimmer === true ?
                <div>
                  <div className='py-3'>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }} >#</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} >Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">	Mobile Number</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Email ID</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Subjects</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Date of joining</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Actions </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            this.state.studentTable.map((row, index) => (
                              <TableRow

                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align='left'>
                                  {row.name}
                                </TableCell>
                               
                                <TableCell align="left">{row.countryCode + " " + row.mobileNumber}</TableCell>
                                <TableCell align="left">{row.email}</TableCell>
                                <TableCell align="left">{row.studentSubject}</TableCell>
                                <TableCell align="left">{
                                  <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                    {row.createdAt}
                                  </Moment>
                                }</TableCell>
                                <TableCell align="left">
                                  <Link to={'/student/student-detail/' + row.id}>
                                    <AiFillEye className='detailIcon' />
                                  </Link>

                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className='d-flex justify-content-center'>
                    {
                      this.state.studentTotalRecords !== 0 ?
                        <Stack spacing={2}>

                          <Pagination color="primary" count={this.state.studentTotalPages} page={this.state.studentPageIndex} onChange={this.studentPageIndex} />
                        </Stack>
                        : "No Data found"
                    }

                  </div>
                </div>
                :
                <ShimmerThumbnail rounded />
            }
          </div>
        </div>
        <div>
          <div className='table-heading d-flex justify-content-between flex-wrap'>
            <div className='tabing-heading-name'>
            Study Material Details
            </div>
          </div>
          <div>
            {
              this.state.isStudyMatarialShimmer === true ?
                <div>
                  <div className='py-3'>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }} >#</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} >Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Class Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Subject Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Tags</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Created Date</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Actions </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            this.state.studyMatarialTable.map((row, index) => (
                              <TableRow

                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align='left'>
                                  {row.name}
                                </TableCell>
                               
                                <TableCell align="left">{row.ClassName }</TableCell>
                                <TableCell align="left">{row.SubjectName}</TableCell>
                                <TableCell align="left">{row.Tags}</TableCell>
                                <TableCell align="left">{
                                  <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                    {row.createdAt}
                                  </Moment>
                                }</TableCell>
                                <TableCell align="left">
                                  <Link to={'/study-matarial/study-matarial-detail/' + row.id}>
                                    <AiFillEye className='detailIcon' />
                                  </Link>

                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className='d-flex justify-content-center'>
                    {
                      this.state.studyMatarialTotalRecords !== 0 ?
                        <Stack spacing={2}>

                          <Pagination color="primary" count={this.state.studyMatarialTotalPages} page={this.state.studyMatarialPageIndex} onChange={this.studyMatrailChange} />
                        </Stack>
                        : "No Data found"
                    }

                  </div>
                </div>
                :
                <ShimmerThumbnail rounded />
            }
          </div>
        </div>
        <div>
          <div className='table-heading d-flex justify-content-between flex-wrap'>
            <div className='tabing-heading-name'>
            Batches
            </div>
          </div>
          <div>
            {
              this.state.isBatchesShimmer === true ?
                <div>
                  <div className='py-3'>

                    <TableContainer component={Paper}>
                      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                        <TableHead>
                          <TableRow>
                            <TableCell style={{ fontWeight: "bold" }} >#</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} >Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Code</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Course Name</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">Class Type</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">No Of Teachers</TableCell>
                            <TableCell style={{ fontWeight: "bold" }} align="left">No Of Student</TableCell>

                            <TableCell style={{ fontWeight: "bold" }} align="left">Actions </TableCell>
                          </TableRow>
                        </TableHead>
                        <TableBody>
                          {
                            this.state.isBatchTable.map((row, index) => (
                              <TableRow

                                key={index}
                                sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                              >
                                <TableCell align="left">{index + 1}</TableCell>
                                <TableCell align='left'>
                                  {row.name}
                                </TableCell>
                               
                                <TableCell align="left">{row.ClassName }</TableCell>
                                <TableCell align="left">{row.SubjectName}</TableCell>
                                <TableCell align="left">{row.Tags}</TableCell>
                                <TableCell align="left">{
                                  <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                    {row.createdAt}
                                  </Moment>
                                }</TableCell>
                                <TableCell align="left">
                                  <Link to={'/study-matarial/study-matarial-detail/' + row.id}>
                                    <AiFillEye className='detailIcon' />
                                  </Link>

                                </TableCell>
                              </TableRow>
                            ))
                          }
                        </TableBody>
                      </Table>
                    </TableContainer>
                  </div>
                  <div className='d-flex justify-content-center'>
                    {
                      this.state.isBatchTotalPages !== 0 ?
                        <Stack spacing={2}>

                          <Pagination color="primary" count={this.state.isBatchTotalPages} page={this.state.isBatchPageIndex} onChange={this.isBatchPage} />
                        </Stack>
                        : "No Data found"
                    }

                  </div>
                </div>
                :
                <ShimmerThumbnail rounded />
            }
          </div>
        </div>
      </div>

    )
  }

}

InstituteDetails.propTypes = {};

InstituteDetails.defaultProps = {};

export default  withRouter(InstituteDetails);
