import React, { Component } from "react";
// import PropTypes from 'prop-types';
import styles from "./Notfication.module.scss";
import Moment from "react-moment";
// import { BsFilter } from 'react-icons/bs'
import Pagination from "@mui/material/Pagination";
import Stack from "@mui/material/Stack";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import UserService from "../../Services/user-services";
import authHeader from "../../Services/auth-header";
import AuthService from "../../Services/auth-services";
import { ShimmerThumbnail } from "react-shimmer-effects-18";
import Multiselect from "multiselect-react-dropdown";
class Notfication extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 5,
      totalPages: 1,
      searchValue: "",
      notficaiationList: [],
      show: false,
      timeFilter: 0,
      notficaionShimmer: false,
      form: {
        title: "",
        message: "",
        user: "",
        image: "",
      },
      options: [],
      formErrors: {
        title: null,
        message: null,
        user: null,
      },
      isImageUpload: 0,
      btndisable: false,
    };
  }
  componentDidMount() {
    const auth = authHeader();
    if (auth == null) {
      return (window.location.href = "/Login");
    } else {
      let params = {
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
      };
      UserService.notficationList(params).then(
        (response) => {
          console.log("response==>", response);
          if (response.data.status === 200) {
            this.setState(
              {
                notficaiationList: response.data.data.notfication,
              },
              () => {
                this.setState({
                  notficaionShimmer: true,
                  totalRecords: response.data.data.totalRecords,
                  pageIndex: response.data.data.pageIndex,
                  totalPages: response.data.data.totalPages,
                });
              }
            );
          }
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
      UserService.allUser().then(
        (response) => {
          // console.log("response==>", response)
          if (response.data.status === 200) {
            // console.log("Called If")
            this.setState(
              {
                options: response.data.data.user,
              },
              () => {
                // console.log(this.state.options)
              }
            );
          } else {
          }
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
    }
  }
  handleChangeRowsPerPage = (e, value) => {
    this.setState({
      pageIndex: value,
    });
  };
  handleChangePage = (e, value) => {
    this.setState({
      pageIndex: 0,
    });
  };
  handleChange = (e, value) => {
    console.log("ValueOfE==>", e, value);
    this.setState({
      pageIndex: value,
    });
    let params = {
      pageIndex: value,
      pageSize: this.state.pageSize,
    };
    UserService.notficationList(params).then(
      (response) => {
        console.log("response==>", response);
        if (response.data.status === 200) {
          this.setState(
            {
              notficaiationList: response.data.data.notfication,
            },
            () => {
              this.setState({
                notficaionShimmer: true,
                totalRecords: response.data.data.totalRecords,
                pageIndex: response.data.data.pageIndex,
                totalPages: response.data.data.totalPages,
              });
            }
          );
        }
      },
      (error) => {
        console.log(error);
        AuthService.logout();
        return (window.location.href = "/Login");
      }
    );
  };
  filterPopup = () => {
    this.setState({
      show: !this.state.show,
    });
  };
  handleChangeDropDown = (e) => {
    this.setState(
      {
        timeFilter: e.target.value,
      },
      () => {
        let params = {
          pageIndex: 1,
          pageSize: this.state.pageSize,
        };
        UserService.notficationList(params).then(
          (response) => {
            console.log("response==>", response);
            if (response.data.status === 200) {
              this.setState(
                {
                  notficaiationList: response.data.data.notfication,
                },
                () => {
                  this.setState({
                    notficaionShimmer: true,
                    totalRecords: response.data.data.totalRecords,
                    pageIndex: response.data.data.pageIndex,
                  });
                }
              );
            }
          },
          (error) => {
            console.log(error);
            AuthService.logout();
            return (window.location.href = "/Login");
          }
        );
      }
    );
  };
  searchValue = (event) => {
    this.setState(
      {
        searchValue: event.target.value,
        pageIndex: 1,
      },
      () => {}
    );

    !!this.timeout && clearTimeout(this.timeout);

    this.timeout = setTimeout(() => {
      let params = {
        pageIndex: 1,
        pageSize: this.state.pageSize,
      };
      UserService.notficationList(params).then(
        (response) => {
          console.log("response==>", response);
          if (response.data.status === 200) {
            this.setState(
              {
                notficaiationList: response.data.data.notfication,
              },
              () => {
                this.setState({
                  notficaionShimmer: true,
                  totalRecords: response.data.data.totalRecords,
                  pageIndex: response.data.data.pageIndex,
                });
              }
            );
          }
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
    }, 800);
  };
  validateField = (name, value, refValue) => {
    let errorMsg = null;
    switch (name) {
      case "title":
        if (!value) errorMsg = "Please enter title.";
        break;
      case "message":
        if (!value) errorMsg = "Please Enter Message.";
        break;
      case "user":
        if (!value) errorMsg = "Please Select User.";
        break;
      default:
        break;
    }
    return errorMsg;
  };
  validateForm = (form, formErrors, validateFunc) => {
    const errorObj = {};
    Object.keys(formErrors).map((x) => {
      let refValue = null;

      const msg = validateFunc(x, form[x], refValue);
      if (msg) errorObj[x] = msg;
      return msg;
    });
    return errorObj;
  };
  handleSubmit = () => {
    const { form, formErrors } = this.state;
    const errorObj = this.validateForm(form, formErrors, this.validateField);
    if (Object.keys(errorObj).length !== 0) {
      this.setState({ formErrors: { ...formErrors, ...errorObj } });
      return false;
    } else {
      UserService.SendNotfication(form).then(
        (response) => {
          console.log("response==>", response);
        },
        (error) => {
          console.log(error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
    }

    this.setState({
      form: { ...this.state.form, title: "", message: "", user: "", image: "" },
    });
    this.setState({ selectedValue: "" });
  };
  handleChangeForm = (e) => {
    const { name, value, checked } = e.target;
    const { form, formErrors } = this.state;
    let formObj = {};
    // console.log("Value==>", name)
    if (name === "language") {
      // handle the change event of language field
      if (checked) {
        // push selected value in list
        formObj = { ...form };
        formObj[name].push(value);
      } else {
        // remove unchecked value from the list
        formObj = {
          ...form,
          [name]: form[name].filter((x) => x !== value),
        };
      }
    } else {
      // handle change event except language field
      formObj = {
        ...form,
        [name]: value,
      };
    }
    this.setState({ form: formObj }, () => {
      if (!Object.keys(formErrors).includes(name)) return;
      let formErrorsObj = {};

      const errorMsg = this.validateField(
        name,
        name === "language" ? this.state.form["language"] : value
      );
      formErrorsObj = { ...formErrors, [name]: errorMsg };

      this.setState({ formErrors: formErrorsObj });
    });
  };
  onSelect = (e) => {
    // console.log('daTA==>', e)
    this.setState({
      form: { ...this.state.form, user: e.map((item) => item.id).join(",") },
    });
    this.setState({ selectedValue: e });
    this.setState({
      formErrors: { ...this.state.formErrors, user: "" },
    });
  };
  onRemove = (e) => {
    console.log("RemoveData==>", e);
    this.setState({
      form: { ...this.state.form, user: e.map((item) => item.id).join(",") },
    });
  };
  onImageChange = (event) => {
    if (event.target.files && event.target.files[0]) {
      let img = event.target.files[0];
      let formData = new FormData();
      this.setState({ btndisable: false });
      formData.append("file", img);
      let params = {
        folder: "Image",
        file: img,
      };
      console.log(params);

      UserService.uploadImage(params).then(
        (response) => {
          console.log("response==>", response);
          if (response.status === 200) {
            console.log("URL==>", response.data.data.fileUrl);
            this.setState(
              {
                form: {
                  ...this.state.form,
                  image: response.data.data.locationUrl,
                },
                btndisable: true,
                isImageUpload: 1,
              },
              () => {
                console.log("THISSTATE==>", this.state.form);
              }
            );
          } else {
          }
        },
        (error) => {
          console.log("Error==>", error);
          AuthService.logout();
          return (window.location.href = "/Login");
        }
      );
    }
  };
  render() {
    return (
      <div className="main">
        <div className="table-heading d-flex justify-content-between flex-wrap">
          <div className="tabing-heading-name">Notfication</div>
          <div className="tableFilterColumn">
            <div
              className={`d-flex justify-content-between flex-wrap tableFilter`}
            >
              <div className="px-3">
                <input
                  type="text"
                  className="form-control searching"
                  placeholder="Search"
                  onChange={this.searchValue}
                ></input>
              </div>
              {/* <div className='px-3'>
                <button className='btn btn-success btn-topper filter  position-relative' onClick={this.filterPopup}>
                  <span >
                    <BsFilter className='filterIcon' />
                  </span>
                  Filter
                </button>
              </div> */}
              <div className="px-3">
                <button className="btn btn-success btn-topper  export">
                  Export
                </button>
              </div>
            </div>
          </div>
        </div>
        <div></div>
        {this.state.show === false ? (
          ""
        ) : (
          <div className="row pt-5">
            <div className="col-lg-3 col-sm-12 ">
              <label className="labelFilter">TimeFilter</label>
              <FormControl className={styles.firstTextbox}>
                <Select
                  className={styles.colorWhite}
                  value={this.state.timeFilter}
                  onChange={this.handleChangeDropDown}
                  displayEmpty
                  MenuProps={{
                    PaperProps: {
                      sx: {
                        "& .MuiMenuItem-root.Mui-selected": {
                          backgroundColor: "#1fb9fc",
                          margin: "0px 6px",
                          color: "#000000",
                          borderRadius: "4px",
                        },
                        "& .MuiMenuItem-root:hover": {
                          backgroundColor: "#1fb9fc",
                          margin: "0px 6px",
                          color: "#000000",
                          borderRadius: "4px",
                        },
                        "& .MuiMenuItem-root.Mui-selected:hover": {
                          backgroundColor: "#1fb9fc",
                          margin: "0px 6px",
                          color: "#000000",
                          borderRadius: "4px",
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value={0}>All</MenuItem>
                  <MenuItem value={1}>Today</MenuItem>
                  <MenuItem value={2}>Last Week</MenuItem>
                  <MenuItem value={3}>Last Month</MenuItem>
                  <MenuItem value={4}>Last Year</MenuItem>
                </Select>
              </FormControl>
            </div>
          </div>
        )}
        <span className="line"></span>
        <div>
          {this.state.notficaionShimmer === true ? (
            <div className="">
              <div className="row m-0 py-3">
                <div className="col-lg-7 col-sm-12 border bg-white p-3">
                  <div
                    className={`d-flex justify-content-between ${styles.headingColor}`}
                  >
                    <div>
                      <label>Title</label>
                    </div>
                    <div>
                      <label>Message</label>
                    </div>
                  </div>
                  <div className={` ${styles.notficationTabs}`}>
                    {this.state.notficaiationList.map((nav, index) => {
                      return (
                        <div
                          key={index}
                          className={`${styles.mainNotficationList}`}
                        >
                          <div className="d-flex justify-content-between flex-wrap">
                            <div>
                              <label className={`${styles.title}`}>
                                {nav.title}
                              </label>
                            </div>
                            <div className={`p-10`}>
                              <label className={`${styles.dates}`}>
                                <Moment
                                  format="YYYY-MMM-DD | HH:mm:ss A"
                                  utc={false}
                                >
                                  {nav.createdAt}
                                </Moment>
                              </label>
                            </div>
                          </div>
                          <div className={"p-10"}>
                            <div className="d-flex justify-content-between flex-wrap">
                              <label className={`${styles.message}`}>
                                {nav.message}
                              </label>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                  </div>
                  <div>
                    <div className="d-flex justify-content-center">
                      {this.state.totalRecords !== 0 ? (
                        <Stack spacing={2}>
                          <Pagination
                            color="primary"
                            count={this.state.totalPages}
                            page={this.state.pageIndex}
                            onChange={this.handleChange}
                          />
                        </Stack>
                      ) : (
                        "No Data found"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-lg-5 col-sm-12 border">
                  {/* Notfication */}
                  <div
                    className={`bg-white shadow ${styles.userNotficationMessage}`}
                  >
                    <div className="row">
                      <div className="col-lg-10 m-auto    col-sm-12">
                        <div className="row py-3">
                          <div className="col-lg-12 col-sm-12">
                            <div className="form-group">
                              <label>
                                Title:<span className="asterisk">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="title"
                                value={this.state.form.title}
                                onChange={this.handleChangeForm}
                                onBlur={this.handleChangeForm}
                              />
                              {this.state.formErrors.title && (
                                <span className="text-danger">
                                  {this.state.formErrors.title}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label>
                                Message:<span className="asterisk">*</span>
                              </label>
                              <input
                                className="form-control"
                                type="text"
                                name="message"
                                value={this.state.form.message}
                                onChange={this.handleChangeForm}
                                onBlur={this.handleChangeForm}
                              />
                              {this.state.formErrors.message && (
                                <span className="text-danger">
                                  {this.state.formErrors.message}
                                </span>
                              )}
                            </div>
                            <div className="form-group">
                              <label>
                                User:<span className="asterisk">*</span>
                              </label>
                              <Multiselect
                                name="user"
                                options={this.state.options}
                                selectedValues={this.state.selectedValue}
                                onSelect={this.onSelect}
                                onRemove={this.onRemove}
                                displayValue="name"
                                value={this.state.form.user}
                                onChange={this.handleChangeForm}
                                onBlur={this.handleChangeForm}
                                keepSearchTerm={false}
                                onInputChange={this.onInputChange}
                                emptyRecordMsg={"No Data Found"}
                              />
                              {this.state.formErrors.user && (
                                <span className="text-danger">
                                  {this.state.formErrors.user}
                                </span>
                              )}
                            </div>
                            {this.state.isImageUpload === 0 ? (
                              <div className="form-group my-3">
                                <div className="col-lg-12">
                                  <div className={styles.uploadOuter}>
                                    <span className={styles.dragBox}>
                                      Upload Thumbnail
                                      <input
                                        type="file"
                                        onChange={this.onImageChange}
                                        className="form-control"
                                      />
                                    </span>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <div className={styles.imgThumbanil}>
                                <img
                                  src={this.state.form.image}
                                  alt=""
                                  className={styles.imgWidth}
                                ></img>
                              </div>
                            )}
                          </div>

                          <div className="d-flex justify-content-center mt-3">
                            <input
                              type="button"
                              className={styles.btnSend}
                              value="Send"
                              onClick={this.handleSubmit}
                              disabled={this.state.btndisable}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ) : (
            <ShimmerThumbnail rounded />
          )}
        </div>
      </div>
    );
  }
}

Notfication.propTypes = {};

Notfication.defaultProps = {};

export default Notfication;
