import { React, useState,useEffect  } from 'react';
import styles from './Layout.module.scss';
import { FaBars } from "react-icons/fa";
import { NavItems } from './navItems'
import { Outlet, NavLink,useLocation } from "react-router-dom";
// import { matchPath } from 'react-router-dom';
import PropTypes from 'prop-types';
import withRouter from '../../Common/with-router';
import authServices from '../../Services/auth-services';
import authHeader from '../../Services/auth-header';
const Layout = (props) => {
  console.log(props)
  const location = useLocation();
  
    console.log(location.pathname)
    let path  = location.pathname;
    console.log("Path==>",path)
    path = path.split('/');
    console.log("Path==>",path)
    let datapth = '/' + path[1];
    console.log("datapth==>",datapth)
  const [isShown, setIsShown] = useState(true);
  const [isLoader,setIsloader] = useState(false)
  const toggle = () => {
    // 👇️ toggle visibility
    setIsShown(current => !current);
  }
  const logout = ()=>{
    authServices.logout()
    props.navigate('/Login')
    window.location.reload();
  }
  useEffect(() => {
    const auth = authHeader()
    console.log("Auth Header==>",auth)
    if(auth == null){
      setIsloader(false)
      return  window.location.href = '/Login';
    }else{
      setIsloader(true)
    }
  },[])
  return (
    <div>
      {
        isLoader === false ? "" :
      
 <div className='container-fluid'>
      <div className='row'>
        <div
          onClick={toggle}
          className={isShown ? styles.nav_overlay : styles.nav_overlayOff} >   </div>

        <div className={isShown ? styles.navColumn : styles.navColumn1}>



          <div className='row'>
            <div className='col-lg-6 col-sm-12 m-auto'>
              <div className={styles.logoIcon}>
                <div className='text-center'>
                  <img src='/imgs/toppr_logo.svg' alt='imgLogo' className={styles.logo}></img>
                </div>
              </div>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12'>
              <nav className={styles.navbarFixed}>
                <ul className={styles.navbar}>
                  {
                    NavItems.map((nav, index) => {
                      return (
                        <NavLink to={nav.url}
                        className={styles.navLink}
                        key={index}
                      >
                        <li className={datapth === nav.url  ? styles.active : '' }>
                        
                            <span className={styles.icons}>
                              {                              
                               datapth === nav.url? <img src={nav.img} alt='' className={styles.imgSize}></img> : <img src={nav.img} alt='' className={styles.imgSize}></img>
                              }
                            </span>
                            <span className={datapth === nav.url ? styles.activeColor : ""}>
                              {nav.title}
                            </span>
                         
                        </li>
                        </NavLink>
                      )


                    })
                  }

                </ul>
              </nav>
            </div>
          </div>
          <div className='row'>
            <div className='col-lg-12 m-auto '>
              <div className={styles.LogoutBtn}>
                <button className={styles.btnLogout} onClick={logout}>
                Log Out
                </button>
                {/* <span className={styles.btnLogout}></span> */}
              </div>
            </div>
          </div>
        </div>
        <div className={isShown ? styles.navColumn2 : styles.navColumn3}>
          {/* <div className={styles.main} style={{width: isShown ? 'calc(100% - 274px)' : '100%'}}> */}
          <div className={styles.topBar}>

            <div className='container-fluid m-0 p-0 '>
              <div className='row w-100 m-0 p-0 '>

                <div className='d-flex justify-content-between '>
                  <div className="p-2">
                    <FaBars className={styles.fontSize28} onClick={toggle} />
                  </div>

                  <div className="p-2">
                    <img src='https://testadmin.unique-tags.com/assets/images/user.png' className={styles.imgAdmin} alt=""></img>
                  </div>
                </div>
              </div>
              <div>
                <Outlet />
              </div>
            </div>
          </div>
        </div>


      </div>


    </div>
}
    </div>
   


  )
}

Layout.propTypes = {};
Layout.contextTypes = {
  router: PropTypes.object
};
// Layout.defaultProps = {};

export default  withRouter(Layout);
