export const NavItems = [
   {
      title:'Dashboard',
      img:'/imgs/dashboard.png',
      url:'/Dashboard'
   },
   {
      title:'Institute',
      img:'/imgs/institute.png',
      url:'/Institute'
   },
   {
      title:'Teachers',
      img:'/imgs/teachers.png',
      url:'/Teacher'
   },
   {
      title:'Students',
      img:'/imgs/student.png',
      url:'/Student'
   },
   {
      title:'Classes',
      img:'/imgs/classes.png',
      url:'/Classes'
   },
   {
      title:'Subjects',
      img:'/imgs/subjects.png',
      url:'/Subject'
   },
   {
      title:'Study Matarials',
      img:'/imgs/StudyMaterials.png',
      url:'/study-matarial'
   },
   // {
   //    title:'Fess',
   //    img:'/imgs/fess.png',
   //    url:'/fees'
   // },
   {
      title:'Notfications',
      img:'/imgs/notification.png',
      url:'/Notfication'
   },
   {
      title:'Pages',
      img:'/imgs/pages.png',
      url:'/pages'
   },
   {
      title:'Setting',
      img:'/imgs/setting.png',
      url:'/Setting'
   }

]