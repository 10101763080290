import React,{Component} from 'react';
// import PropTypes from 'prop-types';
import styles from "./StudyMatarial.module.scss"
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Moment from 'react-moment';
import { BsFilter } from 'react-icons/bs'
import Pagination from '@mui/material/Pagination';
import Stack from '@mui/material/Stack';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import UserService from "../../Services/user-services";
import authHeader from '../../Services/auth-header';
import AuthService from '../../Services/auth-services';
import { ShimmerThumbnail } from "react-shimmer-effects-18";
import {AiFillEye} from 'react-icons/ai'
import { Link } from 'react-router-dom';
class Studymatarial extends Component {
  timeout = null;
  constructor(props) {
    super(props);
    this.state = {
      pageIndex: 1,
      pageSize: 10,
      totalRecords: 5,
      totalPages: 1,
      searchValue: "",
      studyMatarialList: [],
      instituteList:[],
      show: false,
      timeFilter: 0,
      instituteId:"0",
      isStudyMatarialShimmer: false

    };
  }
  componentDidMount() {
    const auth = authHeader()
    if (auth == null) {
      return window.location.href = '/Login';
    } else {
      let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: this.state.pageIndex,
        pageSize: this.state.pageSize,
       
      }
      UserService.StudyMatarial(params).then(
        response => {

          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              studyMatarialList: response.data.data.teacherList,

            }, () => {
              this.setState({

                isStudyMatarialShimmer: true,
                totalRecords: response.data.data.totalRecords,
                pageIndex: response.data.data.pageIndex,

              }

              )

            })
          }

        },
        error => {
          console.log(error)
          if(error.response.status === 401){
          AuthService.logout();
          return window.location.href = '/Login'; 
          }
        }
      );
      let instituteParmas = {
        pageSize: "0",
        instituteId:this.state.instituteId
      }
      UserService.InstiteList(instituteParmas).then(
        response => {

          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              instituteList: response.data.data.InstituteList,
            })
          }

        },
        error => {
          console.log(error)
          if(error.response.status === 401){
          AuthService.logout();
          return window.location.href = '/Login'; 
          }
        }
      );

    }
  }
  handleChangeRowsPerPage = (e, value) => {
    this.setState({
      pageIndex: value
    })
  }
  handleChangePage = (e, value) => {
    this.setState({
      pageIndex: 0
    })
  }
  handleChange = (e, value) => {
    console.log("ValueOfE==>", e, value)
    this.setState({
      pageIndex: value
    })
  }
  filterPopup = () => {
    this.setState({
      show: !this.state.show
    })
  }
  handleChangeDropDown = (e) => {
    this.setState({
      timeFilter: e.target.value
    },
()=>{

    let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: 1,
        pageSize: this.state.pageSize,
      }
      UserService.StudyMatarial(params).then(
        response => {

          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              studyMatarialList: response.data.data.teacherList,

            }, () => {
              this.setState({

                isStudyMatarialShimmer: true,
                totalRecords: response.data.data.totalRecords,
                pageIndex: response.data.data.pageIndex,

              }

              )

            })
          }

        },
        error => {
          console.log(error)
          if(error.response.status === 401){
          AuthService.logout();
          return window.location.href = '/Login'; 
          }
        }
      );
}

    )
  }
  searchValue = (event)=>{
    this.setState(
      {
        searchValue:event.target.value,
        pageIndex:1
      },()=>{
       
       
      }
    )

    !!this.timeout && clearTimeout(this.timeout);
  
    this.timeout = setTimeout(() => {
      let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: 1,
        pageSize: this.state.pageSize,
      }
      UserService.StudyMatarial(params).then(
        response => {

          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              studyMatarialList: response.data.data.teacherList,

            }, () => {
              this.setState({

                isStudyMatarialShimmer: true,
                totalRecords: response.data.data.totalRecords,
                pageIndex: response.data.data.pageIndex,

              }

              )

            })
          }

        },
        error => {
          console.log(error)
          if(error.response.status === 401){
            AuthService.logout();
            return window.location.href = '/Login'; 
          }
        }
      );
    }, 800);
  }
  handleChangeDropDownClasses = (e) => {
    this.setState({
      instituteId: e.target.value
    },
    ()=>{
      let params = {
        searchKey: this.state.searchValue,
        timeFilter: parseInt(this.state.timeFilter),
        pageIndex: 1,
        pageSize: this.state.pageSize,
        instituteId:e.target.value
       
      }
      UserService.StudyMatarial(params).then(
        response => {

          console.log("response==>", response)
          if (response.data.status === 200) {

            this.setState({
              studyMatarialList: response.data.data.teacherList,

            }, () => {
              this.setState({

                isStudyMatarialShimmer: true,
                totalRecords: response.data.data.totalRecords,
                pageIndex: response.data.data.pageIndex,

              }

              )

            })
          }

        },
        error => {
          console.log(error)
          if(error.response.status === 401){
 AuthService.logout();
          return window.location.href = '/Login'; 
          }
         
         
        }
      );
      console.log("e==>",e.target.value)
    }
    )
  }
  render() {
    return (
      <div className='main'>
        <div className='table-heading d-flex justify-content-between flex-wrap'>
          <div className='tabing-heading-name'>
          Study Matarial Management
          </div>
          <div className='tableFilterColumn'>
            <div className={`d-flex justify-content-between flex-wrap tableFilter`}>
              <div className='px-3'>
                <input type='text' className='form-control searching' placeholder='Search' onChange={this.searchValue}></input>
              </div>
              <div className='px-3'>
                <button className='btn btn-success btn-topper filter  position-relative' onClick={this.filterPopup}>
                  <span >
                    <BsFilter className='filterIcon' />
                  </span>
                  Filter
                </button>
              </div>
              <div className='px-3'>
                <button className='btn btn-success btn-topper  export'>Export</button>
              </div>
            </div>
          </div>
        </div>
        <div>
        </div>
        {
          this.state.show === false ? "" :
            <div className='row pt-5'>
              <div className='col-lg-3 col-sm-12 '>
                <label className='labelFilter'>TimeFilter</label>
                <FormControl
                  className={styles.firstTextbox}

                >
                  <Select
                    className={styles.colorWhite}
                    value={this.state.timeFilter}
                    onChange={this.handleChangeDropDown}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          }
                        }
                      }
                    }}
                  >

                    <MenuItem value={0}>All</MenuItem>
                    <MenuItem value={1}>Today</MenuItem>
                    <MenuItem value={2}>Last Week</MenuItem>
                    <MenuItem value={3}>Last Month</MenuItem>
                    <MenuItem value={4}>Last Year</MenuItem>


                  </Select>
                </FormControl>
              </div>
              <div className='col-lg-3 col-sm-12 '>
                <label className='labelFilter'>InstiteList</label>
                <FormControl
                  className={styles.firstTextbox}

                >
                  <Select
                    className={styles.colorWhite}
                    value={this.state.instituteId}
                    onChange={this.handleChangeDropDownClasses}
                    displayEmpty
                    MenuProps={{
                      PaperProps: {
                        sx: {
                          "& .MuiMenuItem-root.Mui-selected": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          },
                          "& .MuiMenuItem-root:hover": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          },
                          "& .MuiMenuItem-root.Mui-selected:hover": {
                            backgroundColor: "#1fb9fc",
                            margin: '0px 6px',
                            color: "#000000",
                            borderRadius: "4px",
                          }
                        }
                      }
                    }}
                  >

                    <MenuItem value={0}>All</MenuItem>
                    {

                    this.state.instituteList.map((row, index) => (
                      <MenuItem value={row.id} key={index}>{row.InstituteName}</MenuItem>
                      

                    ))
                    }
                   


                  </Select>
                </FormControl>
              </div>
            </div>
        }
        <span className='line'></span>
        <div>
          {
            this.state.isStudyMatarialShimmer === true ?
              <div>
                <div className='py-5'>

                  <TableContainer component={Paper}>
                    <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
                      <TableHead>
                        <TableRow>
                          <TableCell style={{ fontWeight: "bold" }} >#</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} >Name</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Institute Name</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Class Name</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Subject Name</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Tags</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Created Date</TableCell>
                          <TableCell style={{ fontWeight: "bold" }} align="left">Actions </TableCell>
                        </TableRow>
                      </TableHead>
                      <TableBody>
                        {
                          this.state.studyMatarialList.map((row, index) => (
                            <TableRow

                              key={index}
                              sx={{ '&:last-child td, &:last-child th': { border: 0, } }}
                            >
                              <TableCell align="left">{index + 1}</TableCell>
                              <TableCell align='left'>
                                {row.name}
                              </TableCell>
                              <TableCell align="left">{
                                row.InstituteName
                             }</TableCell>
                              <TableCell align="left">{row.ClassName}</TableCell>
                              <TableCell align="left">{row.SubjectName}</TableCell>
                              <TableCell align="left">{row.Tags}</TableCell>
                              <TableCell align="left">{
                                <Moment format="YYYY-MMM-DD | HH:mm:ss A" utc={false}>
                                  {row.createdAt}
                                </Moment>
                              }</TableCell>
                              <TableCell align="left">
                              <Link to={'/study-matarial/study-matarial-detail/' + row.id}>
                                  <AiFillEye className='detailIcon'/>
                                </Link>


                              </TableCell>
                            </TableRow>
                          ))
                        }
                      </TableBody>
                    </Table>
                  </TableContainer>
                </div>
                <div className='d-flex justify-content-center'>
                  {
                    this.state.totalRecords !==0 ? 
                    <Stack spacing={2}>

                        <Pagination color="primary" count={this.state.totalPages} page={this.state.pageIndex} onChange={this.handleChange} />
                      </Stack>
                  :"No Data found"
                  }
                
                </div>
              </div>
              :
              <ShimmerThumbnail rounded />
          }
        </div>
      </div>
    )
  }


}

Studymatarial.propTypes = {};

Studymatarial.defaultProps = {};

export default Studymatarial;
