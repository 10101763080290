import axios from "axios";
import { Component } from "react";

// const API_URL = "http://localhost:4005/api/v1/";
const API_URL = 'https://developmentapi.theacademix.com/api/v1/';
class AuthService extends Component {
   
 static login(email, password) {
      console.log = () => {}
      return axios
         .post(API_URL + "/admin/login", {
            email,
            password
         })
         .then(response => {
            if (response.data.status === 200) {
               localStorage.setItem("token", response.data.data.token);
               console.log("LOGIN")
               return response.data;
            } else {
               console.log("NOT LOGIN")
               return response.data;
            }
         });
   }

 static  logout() {
      localStorage.removeItem("token");
   }

}

export default  AuthService;
