import axios from 'axios';
import { Component } from 'react';
import authHeader from './auth-header';
import AuthService from './auth-services';
const API_URL = 'https://developmentapi.theacademix.com/api/v1/';
// const API_URL = "http://localhost:4005/api/v1/"

// console.log = () => {}
class UserService extends Component {

  static async adminDashboard(params) {
    return await axios.post(API_URL + '/admin/dashboard', params, { headers: authHeader() }).then(response => {
      console.log("Response==>", response)
      return response;
    })
      .catch(error => {
        console.log("Error==>", error)
        AuthService.logout();
        return window.location.href = '/Login';
      });
  }
  static async classGraph(params) {
    return await axios.post(API_URL + '/admin/ClassGraph', params, { headers: authHeader() }).then(response => {
      console.log("Response==>", response)
      return response;
    })
      .catch(error => {
        console.log("Error==>", error)
        AuthService.logout();
        return window.location.href = '/Login';
      });

  }
  static async teacherGraph(params) {
    return axios.post(API_URL + '/admin/TeacherGraph', params, { headers: authHeader() })
  }
  static async studentGraph(params) {
    return axios.post(API_URL + '/admin/StudentGraph', params, { headers: authHeader() })
  }
  static async instituteGraph(params) {
    return axios.post(API_URL + '/admin/InstituteGraph', params, { headers: authHeader() })
  }
  static async dashboardInstite() {
    return axios.get(API_URL + '/admin/DashboardInstitute', { headers: authHeader() })
  }
  static async InstiteList(params) {
    return axios.post(API_URL + '/admin/InstituteList', params, { headers: authHeader() })
  }
  static async InstiteDetail(params) {
    return axios.post(API_URL + '/admin/InstituteDetail',
      params
      , { headers: authHeader() })
  }
  static async teacherList(params) {
    return axios.post(API_URL + '/admin/teacherList', params, { headers: authHeader() })
  }
  static async studentList(params) {
    return axios.post(API_URL + '/admin/studentList', params, { headers: authHeader() })
  }
  static async classesList(params) {
    return axios.post(API_URL + '/admin/classRoomList', params, { headers: authHeader() })
  }
  static async subjectList(params) {
    return axios.post(API_URL + '/admin/subjectList', params, { headers: authHeader() })
  }
  static async teacherListDetail(params) {
    return axios.post(API_URL + '/admin/InstituteDetailTeacher',
      params

      , { headers: authHeader() })
  }
  static async studentListDetail(params) {
    return axios.post(API_URL + '/admin/InstituteDetailStudent',
      params
      , { headers: authHeader() })
  }
  static async classListDetail(params) {
    return axios.post(API_URL + '/admin/InstituteDetailClasses',
      params
      , { headers: authHeader() })
  }
  static async TeacherDetail(params) {
    return axios.post(API_URL + '/admin/TeacherDetail',
      params
      , { headers: authHeader() })
  }
  static async StudentDetail(params) {
    return axios.post(API_URL + '/admin/StudentDetail', 
     params
    , { headers: authHeader() })
  }
  static async classRoomDetails(params) {
    return axios.post(API_URL + '/admin/classRoomDetail', params
   , { headers: authHeader() })
  }
  static async classDetailTeacherList(params) {
    return axios.post(API_URL + '/admin/ClassTeacherList', 
    params
    , { headers: authHeader() })
  }
  static async classDetailStudentList(params) {
    return axios.post(API_URL + '/admin/ClassStudentList', params, { headers: authHeader() })
  }
  static async classDetailSubject(pageIndex, pageSize, id) {
    return axios.post(API_URL + '/admin/ClassStudentList', {
      "classId": id,
      "searchKey": "",
      "pageIndex": pageIndex,
      "columnName": "",
      "orderBy": "",
      "pageSize": pageSize,
    }, { headers: authHeader() })
  }

  static async notficationList(params) {
    return axios.post(API_URL + '/admin/notfication', 
     params
    , { headers: authHeader() })
  }
  static async allUser() {
    return axios.post(API_URL + '/admin/allUser', {
      
    }, { headers: authHeader() })
  }
  static async uploadImage(params) {
    return axios.post(API_URL + '/common/uploadFile', params, {
      headers: {
        "Content-Type": "multipart/form-data",

      }
    });
  }
  static async classRoomSubjectStatus(params) {
 

    return axios.put(API_URL + '/admin/ClassStudentSubjectStatus', params, { headers: authHeader() }).then
  }
  static async notficationUser(form) {
    return axios.put(API_URL + '/admin/notficationSend',
      form
      , { headers: authHeader() })
  }
  static async deacivateClassDetails(id, status) {
    return axios.put(API_URL + '/admin/deacivateClassRoomDetail', {
      "classId": id,
      "status": status
    }, { headers: authHeader() })
  }
  static async deacivateTeacherDetails(params) {
    return axios.put(API_URL + '/admin/deacivateTeacherDetail',params , { headers: authHeader() })
  }
  static async deacivateStudentDetails(params) {
    return axios.put(API_URL + '/admin/deacivateStudentDetail', 
    params, { headers: authHeader() })
  }
  static async subjectDetailsDisbale(params) {
    return axios.put(API_URL + '/admin/subjectDisable',
      params
      , { headers: authHeader() })
  }
  static async subjectDetailsDelete(params) {
    return axios.put(API_URL + '/admin/subjectDelete',
      params
      , { headers: authHeader() })
  }
  static async addSubject(params) {
    return axios.post(API_URL + '/admin/Addsubject',
      params
      , { headers: authHeader() })
  }
  static async SendNotfication(params) {
    return axios.post(API_URL + '/admin/sendNotfication',
      params
      , { headers: authHeader() })
  }
  static async deactiveInstitute(params) {
    return axios.put(API_URL + '/admin/instituteDisable',
      params
      , { headers: authHeader() })
  }
  static async ChangePassword(params) {
    return axios.post(API_URL + '/admin/changePassword',
      params
      , { headers: authHeader() })
  }
  static async StudyMatarial(params) {
    return axios.post(API_URL + '/admin/study-material', params, { headers: authHeader() })
  }
  static async StudyMatrialDetail(params) {
    return axios.post(API_URL + '/admin/study-material-detail',
      params
      , { headers: authHeader() })
  }
  static async Privacy(params) {
    return axios.post(API_URL + '/common/getContentPolicyByType',
      params
      , { headers: authHeader() })
  }
  static async EditPrivacy(params) {
    return axios.post(API_URL + '/common/updateContentPolicy',
      params
      , { headers: authHeader() })
  }
  static async StudyMatrialInstitute(params) {
    return axios.post(API_URL + '/admin/InstituteDetailStudyMaterial',
      params
      , { headers: authHeader() })
  }

}


export default UserService;


